import React from 'react'
import './Partners.scss'

import kosmos from '../../resources/KT-Logo-L-H.png'
import asbis from '../../resources/logo_ASBIS_blue_2018.png'
import dell from '../../resources/DT_AuthorizedPartner_4C.png'
import anafra from '../../resources/New_Logo.png'

export default function Partners() {
  return (
    <section id='Partners'>
      <div className="partners-title">
        <h1>Naši partneri</h1>
      </div>
      <div className="partners-images-holder">
        <div className="partner">
          <img onClick={() => window.open("https://kosmostec.com/", "_blank")} src={kosmos} alt="" />
        </div>
        <div className="partner">
          <img onClick={() => window.open("https://online.asbis.sk/", "_blank")} src={asbis} alt="" />
        </div>
        <div className="partner">
          <img onClick={() => window.open("https://www1.euro.dell.com/content/default.aspx?c=sk&l=sk&s=&s=gen&~ck=cr", "_blank")} src={dell} alt="" />
        </div>
        <div className="partner">
          <img onClick={() => window.open("https://smicro.sk/?gclid=CjwKCAiA2fmdBhBpEiwA4CcHze7tVCr5wvMAk_iEDHmloJoSRmxHIQONY1BIriZR1oz3N6QygfSd6RoCwv4QAvD_BwE", "_blank")} src={anafra} alt="" />
        </div>
      </div>
    </section>
  )
}
