import React from 'react'
import cyber_security from '../../resources/it_service.webp'

export default function ItSluzby() {
    return (
        <div className="wwd-slide">
            <div className="wwd-slide-side wwd-left">
                <div className="wwd-left-text">
                    <h1><strong>Služby</strong> IT</h1>
                    <p>Ponúkame komplexnú správu IT infraštruktúry. Postaráme sa o Vaše IT služby prostredie firmy a o Vaše servery a garantujeme Vám zvýšenie efektívnosti IT prostredia. Snažíme sa o zminimalizovanie Vašich nákladov a zvýšenie spokojnosti</p>
                </div>
            </div>
            <div className="wwd-slide-side wwd-right">
                <div className="wwd-mid-img">
                    <img src={cyber_security} alt="" />
                </div>
            </div>
        </div>
    )
}
