import React from 'react'
import './VideoShow.scss'
import ReactPlayer from 'react-player'
import backgroundSVG from '../../resources/thissweb_randomobjects-03.svg';

export default function VideoShow() {
  return (
    <section id='VideoShow'>
      <div className="videosection-container">
        <img src={backgroundSVG} alt="" />
          <div className="videosection-content-left videoshow-content">
            <div className="videosection-info">
              <div className="videosection-header">
                <h2>Začíname tu</h2>
              </div>
              <div className="videosection-desc">
                <p>Ako IT firma so skúsenosťami vám vieme ponúknuť Audit IT infraštruktúry, kde vieme detekovať zraniteľné miesta, následné navrhnúť ďalšie možné riešenia a v konečnom dôsledku ich aj implementovať.</p>
              </div>
            </div>
          </div>
          <div className="videosection-content-right videoshow-content">
            <div className="videosection-video-content">
            <iframe className='videoshow-video' src="https://www.youtube.com/embed/3ftjTliS5NQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
      </div>
    </section>
  )
}

