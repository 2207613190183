import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';
import CreateSluzby from './CreateSluzby';
import './SluzbyBody.scss'
import axios from 'axios';

// This is parent of SluzbyBody

export default function SluzbyBody() {
    const { sluzbyid } = useParams();
    const [allData, setAllData] = useState();
    const [currentData, setCurrentData] = useState();

    // Fetch API when is not
    useEffect(() => {
        if(allData) { 
            handleUrls()
        }
        else { 
            sluzbyBackendFetch()
        }
    }, [allData])

    useEffect(() => {
        if(allData) { 
            handleUrls();
        }
    }, [sluzbyid])

    // HANDLE URL CHANGE
    const handleUrls = () => { 
        var current = allData.find(x => x.url_name === sluzbyid);
        setCurrentData(current)
    }

    // Fetching
    const sluzbyBackendFetch = () => { 
        try {
            axios.get(`${process.env.REACT_APP_VM_URL}/api/sluzby`)
            .then(res => {
                setAllData(res.data)
            })
            .catch(error => { 
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const CreateMenuElements = () => { 
        if(allData) { 
            return (
                <div className="sluzby-left-row">
                    {allData.map((result, index) => { 
                        return (
                            <Link key={index} to={`/sluzby/${result.url_name}`}>
                                <h3>{result.title}</h3>
                            </Link>
                        )
                    })}
                </div>
            )
        }
    }

    return (
        <section id='sluzby'>
            <div className="sluzby-left-panel">
                <p>SLUŽBY A IT RIEŠENIA</p>
                <div className="sluzby-left-buttons">
                    {<CreateMenuElements/>}
                </div>
            </div>
            <CreateSluzby currentData={currentData}/>
        </section>
    )
}
