import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useState } from 'react';
import { Link, } from 'react-router-dom';
import axios from 'axios';
import './admin.scss'
import 'react-quill/dist/quill.snow.css';
import AdminSluzbyData from './AdminSluzbyData';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Admin = () => {
    const [inputs, setInputs] = useState([{
        title: '',
        url_name: '',
        create_date: '',
        author: '',
        like_count: 0
    }])
    const [value, setValue] = useState();
    const [currentId, setId] = useState();
    const [allData, setAllData] = useState();

    // Today Date
    useEffect(() => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '.' + mm + '.' + yyyy;
        setInputs(prev => ({...prev, create_date: today.toString()}))
    }, [])

    // Load data
    useEffect(() => {
        if(!allData) { 
            fetchAPI()
        }
    }, [allData])

    // Return HTML from 
    const html = () => { 
        return {__html: value};
    }

    const submitAPI = (e) => { 
        e.preventDefault()
        if(value.length === 0) { 
            return;
        }
        else { 
            axios.post(`${process.env.REACT_APP_VM_URL}/api/sluzby/post`, {
                subject: value,
                title: inputs.title,
                author: inputs.author,
                like_count: inputs.like_count,
                url_name: inputs.url_name,
                create_date: inputs.create_date
            })
            .then(function (response) {
            })
            .catch(function (error) {
            });
        }
        window.location.reload()
    }

      // Fetching
      const fetchAPI = () => { 
        try {
            axios.get(`${process.env.REACT_APP_VM_URL}/api/sluzby`)
            .then(res => {
                setAllData(res.data)
            })
            .catch(error => { 
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (e) => { 
        if(e.target.name === "url_name") { 
            const updatedValue = e.target.value.replace(/\s/g, '-'); // Remove spaces
            setInputs(prev => ({...prev, [e.target.name]: updatedValue}))
        }
        else { 
            setInputs(prev => ({...prev, [e.target.name]: e.target.value}))
        }
    }

    const handleRefresh = () => { 
        setInputs("")
        setValue("")
        window.location.reload()
    }

    const handleUpdate = () => { 
        axios.post(`${process.env.REACT_APP_VM_URL}/api/sluzby/update`, {
            id: currentId,
            subject: value,
            title: inputs.title,
            url_name: inputs.url_name,
            author: inputs.author
        })
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
        handleRefresh();
    }

    const handleClickCard = (id) => { 
        var store = allData.find(e => e._id === id)
        console.log(store);
        setValue(store.subject)
        setId(id);
        setInputs({
            title: store.title,
            url_name: store.url_name,
            author: store.author,
        })
    }


    const handleRemovePost = (id) => { 
        axios.post(`${process.env.REACT_APP_VM_URL}/api/sluzby/delete`, {
            id: id
        })
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
        handleRefresh();
    }

    return (
        <section className='admin-panel'> 
            <form className='admin-editor admin-side' onSubmit={submitAPI} method="post" action=''>
                <h1 className='admin-title'>Editor</h1>

                <div className="admin-inputs">
                    <input required className='admin-inp-title' value={inputs.title} type="text" name='title' placeholder='title' onChange={handleChange}/>
                    <input required type="text" className='admin-inp-url_name' value={inputs.url_name} name='url_name' placeholder='url_name' onChange={handleChange}/>
                    <input required type="text" name='author' placeholder='author' value={inputs.author} onChange={handleChange}/>
                </div>
                <div className="rich-text-editor">
                    <ReactQuill required className='react-quill' theme="snow" value={value} onChange={setValue} />
                </div>
                <div className="admin-submit-btn admin-button-area">
                    <button className='admin-submit-button admin-button' onClick={submitAPI} type='submit'>Submit</button>
                </div>
                <div className="admin-update-btn admin-button-area">
                    <button disabled={value ? false : true} onClick={handleUpdate} className='admin-update-button admin-button' type='button'>Update</button>
                </div>
            </form>
            <div className="admin-content-container admin-side">
                    <h1 className='admin-title'>Live Preview</h1>
                    <div className="react-quill-container">
                        <div className="admin-preview-info">
                            <p>Title: {inputs.title}</p>
                            <p>Url: /sluzby/{inputs.url_name}</p> 
                            <p>Author: {inputs.author}</p> 
                            <p>Create Date: {inputs.create_date}</p>
                        </div>
                        <hr />
                        <h1 className='admin-preview-title'>{inputs.title}</h1>
                        <div className='react-quill-content' dangerouslySetInnerHTML={html()} />
                    </div>
                </div>
                <div className="admin-edit-panel">
                    <h1>ALL CREATED POSTS</h1>
                    {allData ? <AdminSluzbyData removePost={handleRemovePost} getBack={handleClickCard} allData={allData}/> : null}
                </div>
        </section>
    );
}

export default Admin;
