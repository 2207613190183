import React, { useEffect, useState } from 'react'
import './BlogBody.scss'
import { useParams } from 'react-router';
import ProductsServices from './subblog/ProductService';
import KybernetickaBezpecnost from './subblog/KybernetickaBezpecnost'
import SpravaSiete from './subblog/SpravaSiete'


export default function BlogBody() {
  const { endpoint } = useParams();
  const [currentData, setCurrentData] = useState();

  const siteSettings = [
    {
      url_name: 'preco-je-audit-it-nevyhnutny',
      header: 'Prečo je audit IT nevyhnutný?',
      category: 'Produkty a Sluzby',
      builder: <ProductsServices/>
    },
    {
      url_name: 'je-rozdiel-medzi-sukromnym-a-firemnym-e-mailom',
      header: 'Je rozdiel medzi súkromným a firemným e-mailom?',
      category: 'Kybernetická bezpečnosť',
      builder: <KybernetickaBezpecnost/>
    },
    {
      url_name: 'sprava-siete-podla-thiss',
      header: 'Správa siete podľa thiss',
      category: 'Nezaradané',
      builder: <SpravaSiete/>
    },

  ]


  useEffect(() => {
    if(siteSettings) { 
      handleUrls();
    }
  }, [endpoint])


  const handleUrls = () => { 
    var current = siteSettings.find(x => x.url_name === endpoint);
    setCurrentData(current)
}

  return (
    <section className='blog'>
      <div className="blog-container">
        <div className="blog-header">
          <h2>{currentData?.header}</h2>
          <h4>Kategoria: {currentData?.category}</h4>
        </div>
        <div className='blog-content'>
          {currentData?.builder}
        </div>
      </div>
    </section>
  )
}
