import React from 'react'

export default function ProductsServices() {
  return (
    <>
      <div className="blog-content-title">
            <h3>Čo spadá pod správu siete?</h3>
          </div>
          <div className="blog-content-desc">
            <p>Všetky komplexné sieťové riešenia na vysokej návrhovej a bezpečnostnej úrovni. Pod správu siete je u nás zahrnutý návrh a analýza projektu, konzultácie a poskytnutie technickej odbornosti nášmu zákazníkovi. Taktiež samotné dodanie materiálu a inštalácia. V neposlednom rade samotná správa siete, kontrola zabezpečenia sietí, pravidelná údržba a aktualizácia, pri neustálej podpore našich zákazníkov.</p>
            <br />
            <h3>Je rozdieľ medzi bežnou správou IT siete a medzi správou siete podľa nás?</h3>
            <p>Hlavným rozdieľom je určite osobitný prístup ku každému existujúcemu alebo aj novému zákazníkovi. Ďalšou výhodou spolupráce s nami sú dlhoročné skúsenosti v správe siete či už pre malé ale aj väčšie firmy, pri ktorých aplikujeme aj rozsiahlejšie a zložitejšie sieťové riešenia. Správu siete spájame taktiež aj so správou serverov, kde navrhujeme mnohé moderné a efektívne riešenia. Či už vo forme “cloud”, čiže virtuálny server alebo individuálne riešenia podľa požiadaviek zákazníkov.</p>
            <br />
            <h3>Výhody správy siete</h3>
            <ul>
                <li>Máte otázky? Vždy sme pripravení komunikovať so zákazníkom, zodpovedať jeho otázky a poskytnúť mu plnohodnotnú podporu. A nemusí ísť iba o správu siete.</li>
                <li>Individuálne ceny na mieru</li>
                <li>Monitoring a kybernetická bezpečnosť</li>
                <li>Inovatívne a efektívne riešenia nášho odborného tímu</li>
            </ul>
            <p>Máte záujem prekonzultovať s nami aktuálny stav vašej siete alebo infraštruktúry? Máte akékoľvek otázky ohľadom serverov, siete? Kontaktujte nás a my preveríme ako je na tom Vaša aktuálna infraštruktúra alebo navrhneme nové možné riešenia.</p>
          </div>
    </>
  )
}
