import React, { useState } from 'react'
import './Footer.scss'
import Logo from '../../resources/1 1.png'
import axios from 'axios'
import { useEffect } from 'react'


export default function Footer() {

    const [data, setData] = useState();


    const fetchAPI = () => { 
        try {
            axios.get(`${process.env.REACT_APP_VM_URL}/api/sluzby`)
            .then(res => {
                setData(res.data)
            })
            .catch(error => { 
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchAPI()
    }, [])

    

    const CreateFooter = () => { 
        return (
            data.map((res, index) => { 
                if(index < 5) { 
                    return (
                        <li key={index}><a href={`/sluzby/${res?.url_name}`}>{res?.title}</a></li>
                    )
                }
                else { 
                    return null;
                }
            })
        )
    }

    return (
        <section id='Footer'>
            <div className="footer-logo">
                <img src={Logo} alt="logo" />
                <p>&copy; 2018 - {new Date().getFullYear()} thiss s.r.o </p>
            </div>
            <div className="footer-wrapper">
                <div className="footer-info footer-wrapper-contant">
                    <h3>INFORMÁCIE</h3>
                    <div className="footer-info-center">
                        <ul>
                            <li>
                                <a href = "mailto: support@thiss.tech"><i className="fa-solid fa-envelope"></i>support@thiss.tech</a>
                            </li>
                            <li>
                                <a rel="noreferrer" href='https://goo.gl/maps/cU2qkkGgSSUZ9FE68' target="_blank"><i className="fa-sharp fa-solid fa-location-dot"></i>Mlynské Nivy 56, 821 05 Bratislava</a>
                            </li>
                            <li>
                                <a href = "tel: +421 914 222 000"><i className="fa-solid fa-phone"></i>+421 914 222 000</a>
                            </li>
                            <li>
                                <p>IČO: 47669217</p>
                            </li>
                            <li>
                                <p>DIČ: 2024083666</p>
                            </li>
                            
                            {/* <li>
                                <Link to={""}>Certifikaty</Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-extra footer-wrapper-contant">
                    <h3>DOPLNKY</h3>
                    <div className="footer-extra-center">
                        <ul>
                            {data? <CreateFooter/> : null}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
