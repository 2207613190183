import React, { useEffect } from 'react'
import landing_background from '../../resources/web_strasidla_LP.webp';
import "./Landing.scss"
import axios from 'axios';

export default function Landing() {

  return (
    <section id='Landing'>
        <div className="landing-container">
            <div className="landing-left landing-split">
                <div className="landing-left-content">
                    <div className="landing-left-title">
                        <h1><strong className='white'>Strašia</strong> vás náklady na IT?</h1>
                    </div>
                    <div className="landing-left-desc">
                        <p>
                        Ako IT firma sme na trhu od roku 2018. Ponúkame IT služby pre malé, stredné ale aj veľké firmy.
                        </p>
                    </div>
                    <div className="landing-left-target">
                        <button onClick={() => {window.location.href = "/cennik"}}>Poďme na to</button>
                    </div>
                </div>
            </div>
            <div className="landing-right landing-split">
                <img src={landing_background} alt="" />
            </div>
        </div>
    </section>
  )
}
