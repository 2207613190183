import React from 'react'
import cloud_server from '../../resources/cloud_server_3d.webp'

export default function CloudServer() {
    return (
        <div className="wwd-slide">
            <div className="wwd-slide-side wwd-left">
                <div className="wwd-left-text">
                    <h1><strong>Cloudové</strong> Služby</h1>
                    <p>Pri cloudových službách pre Vás zabezpečíme vytvorenie a správu serverov, pričom vám vieme navrhnúť individuálne riešenie vášho vlastného servera alebo vieme vytvoriť „virtuálny“ cloudový server. </p>
                </div>
            </div>
            <div className="wwd-slide-side wwd-right">
                <div className="wwd-mid-img">
                    <img src={cloud_server} alt="" />
                </div>
            </div>
        </div>
    )
}
