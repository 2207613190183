import React from 'react'
import { Link } from 'react-router-dom'
import './Onas.scss'
import Logo from '../../resources/1 1.png'

import chronos from '../../resources/Chronos-logo-1.png'
import dell from '../../resources/Dell_Logo-300x91-1.png'
import microsoft from '../../resources/Microsoft_logo_2012.svg_-300x64-1.png'
import nextcloud from '../../resources/nextcloud-e1595243581418.png'
import sogo from '../../resources/sogo-e1595242971841.png'
import veeam from '../../resources/Veeam_logo_2017_black-500-300x89-1.png'
import vmware from '../../resources/Vmware-logo-300x49-1.png'
import zoom from '../../resources/zoom.png'
import Certifikat from '../cert/Certifikat'

export default function Onas() {
  return (
    <div className='onas'>
        <div className="onas-holder">
            <div className="onas-title">
                <h1>Niečo o našej <strong>spoločnosti</strong></h1>
            </div>
            <div className="onas-middle">
                <div className="onas-logo">
                    <img src={Logo} alt="" />
                </div>
                <div className="onas-desc">
                    <p>
                       <strong>Spoločnosť</strong> thiss vznikla v roku 2016 oddelením od svojej materskej spoločnosti Pantheon Technologies. Už od svojich začiatkov zabezpečujeme pre našich klientov služby v oblasti rozvoja IT, manažmentu IT, informácií a investícií IT.
                        Zameriavame sa na systémovú integráciu a naše riešenia sú moderné a  inteligentného IT Vám vieme pomôcť aj so systémom CRM (Customer Relations Management), správou a rozvojom infrašktruktúry, virtualizáciou HW zdrojov, wifi riešeniami, kyber bezpečnosťou a ďalšími. Ako držitelia certifikátov ( <strong>ISO/IEC 27001:2013 / ISO 9001:2015</strong> ) Vám vieme zaručiť spokojnosť s našimi službami ako komplexná správa IT, audit it a kybernetická bezpečnosť, správa siete, riešenie úložiska a cloudové služby, komunikačné riešenia.
                    </p>
                    <br />
                    <p>
                        Našou prioritou je korektnosť a slušnosť k zákazníkom rovnako ako kvalitné SLA podľa Vašich potrieb. Na naše riešenia a podporu sa môžete spoľahnúť v každej situácii. S našimi službami Vám znížime náklady, zvýšime efektivitu Vašej práce a zvýšime Váš zisk. V prípade záujmu o spoluprácu alebo ak máte na nás nejakú otázku, neváhajte nás kontaktovať.
                    </p>
                </div>
            </div>
            <div className="onas-policy">
                <Link to={'/return-policy'}>Reklamačný Poriadok</Link>
                <Link to={'/tos'}>Všeobecné obchodné podmienky</Link>
                <Link to={'/personal-data'}>Spracovanie osobných údajov</Link>
                <Link to={'/zasady-pouzivania-cookies'}>ZÁSADY POUŽÍVANIA SÚBOROV COOKIE (EÚ)'</Link>
            </div>
            <Certifikat/>
            <div className="onas-tech">
                <div className="onas-title">
                    <h1>Naši partneri a <strong>technológie</strong></h1>
                </div>
                <div className="onas-images">
                    <img src={chronos} alt="chronos_img" />
                    <img src={dell} alt="dell_img" />
                    <img src={microsoft} alt="microsoft_img" />
                    <img src={nextcloud} alt="nextcloud_img" />
                    <img src={sogo} alt="sogo_img" />
                    <img src={veeam} alt="veeam_img" />
                    <img src={vmware} alt="vmware_img" />
                    <img src={zoom} alt="zoom_img" />
                </div>
            </div>
        </div>
    </div>
  )
}
