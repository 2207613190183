import React from 'react'

export default function ProductsServices() {
  return (
    <>
      <div className="blog-content-title">
            <h3>Aký je medzi nimi rozdiel?</h3>
          </div>
          <div className="blog-content-desc">
            <p>Mysleli ste si že, medzi týmito dvoma typmi e-mailov je určite nejaký rozdiel? Správne! Nie je e-mail ako e-mail. Rozdieľov medzi nimi je mnoho. Či už z pohľadu prístupu, bezpečnosti, etiky alebo ceny.</p>
            <br />
            <h3>Prístup k e-mailovému účtu</h3>
            <p>Pri osobnom e-mailovom účte, sa môžete k nemu pripojiť kdekoľvek (hmm, jedine ak ste pripojený k internetu) ale pri firemnom e-maile to tak byť už nemusí. Taktiež si určite na osobnom e-maily v niektorých prípadoch zmeníte heslo alebo iné nastavenie jednoduhšie ako na firemnom účte, kde sa o tieto veci stará IT oddelenie alebo externá IT firma. Zdá sa Vám to zbytočne zložitejšie? Zdať sa môže ale bezpečnosť a dôležitosť je pre firmy nevyhnutná. Alebo do momentu pokiaľ Váš zamestnanec nestiahne vírus v prílohe e-mailu alebo Vám uniknú firemné e-maily s citlivýmí firemnými informáciami.</p>
            <br />
            <h3>Bezpečnosť e-mailov pre firmy</h3>
            <p>Ak máte vo firme kvalitné IT oddelenie alebo Vám vašu IT infraštruktúru spravujú odborníci, určite pri e-mailovom riešení čakajte kvalitné zabezpečenie. Či už sú to heslá, bezpečnostná kontrola e-mailov, blokovanie nevhodných e-mailov a vírusov alebo celkové zabezpečnie rýchleho prístupu a posielanie a prijímania všetkých e-mailov.</p>
            <br />
            <h3>Prístup ľudí k osobnému a firemnému e-mailu</h3>
            <p>Taktiež je rozdieľ ako pristupujú používatelia k písaniu e-mailov. Určite ak píšete e-mail kamošovi z osobného e-mailu a ak v práci posielate dôležitý pracovný e-mail. Či už v oslovení, vyjadrovaní a celkovej formálnej úprave. Taktiež podpis firemného e-mailu, môže vytvárať lepší dojem.
                Pre príklad môžeme uviesť e-mail s oslovením o spoluprácu z e-mailu fero336@mail.com alebo z e-mailu jan.fero@thiss.tech. Asi v rýchlosti viete určiť, ktorý vyzerá dôveryhodnejšie a viac na úrovni. (E-mailové adresy sú vymyslené)
            </p>
            <br />
            <p>Rozdiely medzi súkromným a firemným e-mailovým riešením sme si vysvetlili. Ak máté záujem zabezpečiť Vaše firemné e-maily alebo zlepšiť aktuálne e-mailové riešenie ktoré používate, kontaktujte nás, radi Vám poradíme alebo navrhneme nové efektívne riešenia.</p>
          </div>
    </>
  )
}
