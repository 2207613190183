import React from 'react'
import cyber_security from '../../resources/cyber_security.webp'

export default function CyberSec() {
    return (
        <div className="wwd-slide">
            <div className="wwd-slide-side wwd-left">
                <div className="wwd-left-text">
                    <h1><strong>Kybernetická</strong> bezpečnosť</h1>
                    <p>Údaje sú veľmi zranitelná súčasť podnikania. Veľa zákazníkov toto zistí až pri strate údajov. My pre Vás dokážeme po vzájomenj dohode vykonať analýzu celej IT infrašturkútry a následne navrhneme ďalšie možné riešenia pre maximálne zabezpečenie Vašej firmy, Vašich súborov a informácií. Tento proces je u nás pod službou Audit IT.</p>
                </div>
            </div>
            <div className="wwd-slide-side wwd-right">
                <div className="wwd-mid-img">
                    <img src={cyber_security} alt="" />
                </div>
            </div>
        </div>
    )
}
