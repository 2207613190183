import React, { useEffect, useState } from 'react'
import Admin from '../const/adminpanel/Admin'
import axios from 'axios';
import Header from '../const/header/Header';


export default function AdminPanel() {
  const [right, setRight] = useState(false);

  const Auth = async () => { 
    const getBack = await axios.get(`${process.env.REACT_APP_VM_URL}/auth/admin`, {
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(res => { return res.data })
    var password = prompt('Please Enter password')
    if(password === getBack.password) { 
      setRight(true)
    }
    else { 
      setRight(false)
    }
  }

  const RenderSite = () => { 
    if(right) { 
      return (
        <>
        <Header/>
        <Admin/>
        </>
      )
    }
    else { 
      return ( 
        <p style={{color: "white"}}>No Access</p>
      )
    }
  }

  useEffect(() => {
    Auth();
  }, []);

  return (
    <>
    <RenderSite />
    </>
  )
}
