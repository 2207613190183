import React from 'react'
import { useState,  useEffect} from 'react'

export default function Cennikbody( {data, callbackData} ) {
    const [offer ,setOffer] = useState(5)
    const [list, setList] = useState([])

    const handleChange = (e) => {
        setOffer(prev => e.target.name)
        callbackData(e.target.name)
    }

    const CreateRow = () => { 
        return (
            list.map((result, index) => { 
                return (
                    <label key={index} className={`cennik-select-row ${index === 0 ?`cennik-m` : ""}`}> 
                        <div className="cennik-row-start cennik-rows">
                            <input checked={offer === index.toString()} onChange={handleChange} type="radio" name={index} />
                        </div>
                        <div className="cennik-row-mid cennik-rows">
                            <h1>{result.title}</h1>
                            <p>{result.price}</p>
                        </div>
                        <div className="cennik-row-end cennik-rows"></div>
                    </label>
                )
            })
        )
    }

    const Offer = () => {
        if(offer === 5) { 
            return
        }
        else { 
            return (
                <div className="cennik-vyhody">
                    <ul>
                        {list[offer].benefits.map((result, index) => {
                            return (
                                <li key={index}>{result}</li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }

    useEffect(() => {
        setList(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (
        <>
            <div className="cennik-list-container">
                <div className="cennik-list-left-side cennik-s">
                    <div className="cennik-vyhody-top">
                        <h1 style={{textTransform: "uppercase"}}>{list[offer]?.title}</h1>
                        <p>{list[offer]?.price}</p>
                    </div>
                    <Offer/>
                    <div className="cennik-vyhody-desc">
                        <p>{list[offer]?.description}</p>
                    </div>
                </div>
                <div className="cennik-right-side cennik-s">
                    <CreateRow/>
                </div>
            </div>
        </>
    )
}