import React from 'react'
import Footer from '../const/footer/Footer'

export default function Error() {
  return (
    <>
      <div className='error-page'>
        <h2>Hmm, vyzerá to tak, že tato podstranka neexistuje</h2>
        <button onClick={() => {window.location.href = "/"}}>Späť hlavnú stránku</button>
      </div>
      <Footer/>
    </>
  )
}
