import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import ReactGA from "react-ga4";

// Builder 
import LandingPage from "./routes/LandingPageBuilder"; //LandingPage
import SlaCennikBuilder from "./routes/SlaCennikBuilder"; //SlaCennikBuilder
import EmailCennikBuilder from "./routes/EmailCennikBuilder"; //EmailCennikBuilder
import CennikBuilder from "./routes/CennikBuilder"; //CennikBuilder
import Error from "./routes/errorBuilder"; //Error
import OrderSla from "./routes/OrderSlaBuilder"; //OrderSlaBuilder
import SlaComplete from "./routes/SlacompleteBuilder"; //SlaComplete
import Contact from "./routes/ContactBuilder"; //Contact
import Sluzby from "./routes/SluzbyBuilder"; //Sluzby
import AdminPanel from './routes/AdminPanel';
import Eshop from './routes/Eshop';
import ProductPage from './routes/ProductPageRoute';

import Blog from './routes/BlogBuilder';
import OnasBuilder from './routes/OnasBuilder';
import ReturnPolicyPanel from './routes/ReturnPolicyPanel';
import ToSBuilder from './routes/ToSBuilder';
import PersonalDataBuilder from './routes/PersonalDataBuilder';
import CookiesDocs from './const/cookies-docs/cookiesDocs';
import CookiesPopUp from './const/cookiespopup/CookiesPopUp';

// import CreateSluzby from "./const/sluzby/CreateSluzby";

function App() {

  useEffect(() => {
    const cookies = Cookies.get('cookies-accept')

    if(cookies) { 
      ReactGA.initialize('G-HW6J4WWJCR');
      ReactGA.send({page:window.location.pathname + window.location.search});
    }
    else { 
    }
  }, []);


  return (
    <>
      <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/cennik" element={<CennikBuilder/>} />
          <Route path="/cennik/sla" element={<SlaCennikBuilder/>} />
          <Route path="/cennik/email" element={<EmailCennikBuilder/>} />
          <Route path="/cennik/continue/:categoryId/:indexId/:paramId" element={<OrderSla/>} />
          <Route path="/complete" element={<SlaComplete/>} />
          <Route path="/kontakt" element={<Contact/>} />
          <Route path="/sluzby/:sluzbyid" element={<Sluzby/>} />
          <Route path="*" element={<Error/>} />
          <Route path="/admin" element={<AdminPanel/>} />
          <Route path="/blog/:endpoint" element={<Blog/>} />
          {/* <Route path="/shop/:urlid" element={<Eshop/>} />
          <Route path="/shop/product/:productId/*" element={<ProductPage/>} /> */}
          <Route path="/onas" element={<OnasBuilder/>} />
          <Route path="/return-policy" element={<ReturnPolicyPanel/>} />
          <Route path="/tos" element={<ToSBuilder/>} />
          <Route path="/personal-data" element={<PersonalDataBuilder/>} />
          <Route path="/zasady-pouzivania-cookies" element={<CookiesDocs/>} />
      </Routes>
      {<CookiesPopUp/>}
    </>
  );
}

export default App;




