import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./Header.scss"
import Logo from '../../resources/1 1.png'

export default function Header() {
    const [burger, setBurger] = useState(false) // True = Open False = Closed
    const [blogDropdown, setBlogDropdown] = useState(false) // True = Open False = Closed


    const handleBurger = () => { 
        setBurger(c => c = !c)
    }

    const handleDropdown = () => { 
        setBlogDropdown(c => c = !c)
    }

    const KlientskaZona = () => { 
        return (
            <button onClick={() => {window.open("http://support.thiss.tech/", '_blank')}}>
                Klientská zóna
            </button>
        )
    }
    

  return (
    <>
        {/* <div className="alert">
            <p>Niektore veci este niesu dokoncene (videa, popisy), stevo to musi vymysliet kvoli SEO</p>
        </div> */}
        <nav id='navBar'>
        <div className="nav-mobile-wrapper">
            <Link to={"/"}>
                <div className="mobile-img">
                    <img src={Logo} alt="thiss-tech-logo" />
                </div>
            </Link>

            <div className="burger-wrapper" onClick={handleBurger}>
                <div className="nav-hamburger-button">
                    <i className={burger ? "fa-solid fa-x fa-2x" : "fa-solid fa-bars fa-2x"}></i>
                </div>
            </div>
        </div>


        <div className="nav-left nav-sites">
            <Link to={"/"}>
                <div className="nav-img">
                    <img src={Logo} alt="thiss-tech-logo" />
                </div>
            </Link>
            <div className="nav-buttons">
                <Link to="/sluzby/domov">
                    Služby
                </Link>
                <Link to="/cennik">
                    Cenník
                </Link>
                <div className="header-blog" onClick={handleDropdown}>
                    <a>Blog <i className="fa-solid fa-caret-down"></i> </a>
                    <div className={`header-dropdown ${blogDropdown ? "" : "hidden"  }`}>
                        
                        <Link to="/blog/preco-je-audit-it-nevyhnutny">
                            Produkty a Sluzby
                        </Link>
                        <Link to="/blog/je-rozdiel-medzi-sukromnym-a-firemnym-e-mailom">
                            Kybernetická bezpečnosť
                        </Link>
                        <Link to="/blog/sprava-siete-podla-thiss">
                            Správa siete podľa thiss
                        </Link>

                    </div>
                </div>
                <Link to="/kontakt">
                    Kontakt
                </Link>
                <Link to="/onas">
                    O nás
                </Link>
            </div>
        </div>
        <div className="nav-right nav-sites">
            <div className="nav-socials">
                <a className='nav-ig' href="https://www.instagram.com/thiss.tech/"><i className="fa-brands fa-instagram"></i></a>
                <a href="https://www.facebook.com/thiss.tech/" className='nav-fb'><i className="fa-brands fa-facebook"></i></a>
            </div>
            {/* <div className="nav-right-login">
                <Link
                    id='header-shop-link'
                    to="/shop/all">
                    <i className="fa-solid fa-user"></i>
                </Link>
            </div> */}
            <div className="nav-target-button">
                <KlientskaZona/>
            </div>
        </div>
    </nav>

    <div className={`burger-menu ${burger ? "" : "hidden"  }`}>
                <Link to="/sluzby/domov">
                    Služby
                </Link>
                <Link to="/cennik">
                    Cenník
                </Link>
                <Link to="/kontakt">
                    Kontakt
                </Link>
                {/* <Link to="/blog">
                    Blog
                </Link> */}
                <Link to="/onas">
                    O nás
                </Link>
                {/* <Link 
                    id='header-shop-link'
                    to="/shop/all">
                        Prihlasit sa
                </Link>  */}
        <div className="burger-target nav-target-button">
            <KlientskaZona/>
        </div>
    </div>
</>
  )
}



