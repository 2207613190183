import React from 'react'
import ContactBody from '../const/contact/ContactBody'
import DEV from '../const/DEV/DEV'
import Footer from '../const/footer/Footer'
import Header from '../const/header/Header'
import Popup from '../const/popup/Popup'


export default function Contact() {
  return (
    <>
        <Header/>
        <ContactBody/>
        <Footer/>
    </>
  )
}
