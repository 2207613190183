import React from 'react'
import './PersonalData.scss'

export default function PersonalData() {
  return (
    <div className='personal-data'>
        <div>
            <h1>Spracovanie osobných údajov</h1>
        </div>
        <div>
            <p>
                <br />
                1.Informácie o podmienkach spracúvania osobných údajov
                Podľa článku 13 Nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679 z 27 apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len „Nariadenie“). Tieto informácie sú určené pre návštevníkov nášho webového sídla a uchádzačov sa o zamestnanie, ktorých osobné údaje sme ako prevádzkovateľ webového sídla www.thiss.tech priamo získali priamo od dotknutých osôb a sú spracúvané obchodnou spoločnosťou thiss s.r.o., so sídlom Mlynské nivy 56, 821 05 Bratislava, IČO: 47 669 217, zapísaná v Obchodnom registri Okresného súdu Bratislava I, odd. Sro, vložka č. 122069/B (ďalej ako „thiss s.r.o.“ alebo aj ako „Prevádzkovateľ“).
                Na účely spracovania osobných údajov spoločnosť thiss s.r.o. využíva aj externých dodávateľov služieb, ktorí časť osobných údajov spracúvajú v mene spoločnosti thiss s.r.o.
                Sprostredkovateľom sú externí dodávatelia účtovných, konzultačných a poradenských služieb spoločnosť JTC s. r. o., so sídlom Mlynské nivy 56, 821 05 Bratislava, IČO: 35 906 391  zapísaná v Obchodnom registri Okresného súdu Bratislava I, odd. Sro, vložka č. 33722/B a spoločnosť Pantheon Group s.r.o., so sídlom Mlynské nivy 56, 821 05 Bratislava, IČO: 46 544 569  zapísaná v Obchodnom registri Okresného súdu Bratislava I, odd. Sro, vložka č. 79717/B (ďalej ako „Sprostredkovateľ“).
                Základnými zásadami spracúvania osobných údajov dotknutých osôb sú:
                „Zásada zákonnosti, spravodlivosti a transparentnosti“ – osobné údaje dotknutých osôb spracúvame vždy zákonným spôsobom, spravodlivo a transparentne,
                „Zásada obmedzenie účelu“ – osobné údaje dotknutých osôb sú získavané na konkrétne určené, výslovne uvedené a legitímne účely a nie sú ďalej spracúvané spôsobom, ktorý nie je zlučiteľný s pôvodným účelom ich spracúvania,
                „Zásada správnosti“ – spracúvame iba osobné údaje, ktoré sú správne a podľa potreby aj aktualizované,
                „Zásada minimalizácie údajov“ – spracúvame iba konkrétne osobné údaje, ktoré sú nevyhnutné pre dosiahnutie účelu ich spracúvania,
                „Zásada minimalizácie uchovávania“ – uchovávame Vaše osobné údaje vo forme, ktorá umožňuje Vašu identifikáciu iba dovtedy, kým je to potrebné na účely, na ktoré sa osobné údaje spracúvajú,
                „Zásada integrity a dôvernosti“ –osobné údaje spracúvame spôsobom, ktorý zaručuje ich primeranú bezpečnosť, vrátane ochrany pred neoprávneným alebo nezákonným spracúvaním a náhodnou stratou, zničením alebo poškodením, a to prostredníctvom primeraných technických alebo organizačných opatrení.
                <br />
                <br />
                2.  Účel a právny základ spracúvania osobných údajov
                <br />
                2.1 Vaše osobné údaje naša spoločnosť spracováva na nasledujúce účely:
                –     predovšetkým za účelom kontaktovania, spracovať vašu žiadosť o zamestnanie a posúdiť vašu kandidatúru (ak sa záujemca uchádza o pracovnú pozíciu v spoločnosti thiss s.r.o.)
                –   Za účelom zlepšovania sa v kvalite služieb a zlepšovania nášho webového sídla a na základe informácií o tom, ako ho používate a tieto informácie zhromažďujeme prostredníctvom súborov cookies (viď nižšie)
                <br />
                <br />
                2.2 Ak si služby našej spoločnosti vyžadujú registráciu alebo vyplnenie žiadosti, je nevyhnutné zhromažďovať osobné údaje, ako napríklad meno, e-mailovú adresu, kontaktné údaje a informácie ktoré sú obsahom životopisu, aby personálne oddelenie mohlo spravovať žiadosti uchádzačov sa o zamestnanie, prípadne stáž. 
                <br />
                <br />
                2.3 Informácie zhromažďujeme prostredníctvom súborov cookies. Zhromažďujeme nasledujúce informácie:
                Zemepisná poloha
                Čas návštevy, navštívené stránky a čas strávený na každej z webových stránok
                Odkazujúce informácie o stránke (ako je URI, cez ktorý sa používateľ dostal na túto stránku)
                Doména, druh prehliadača a jazyk
                Krajina a časové pásmo
                Zvyky pri klikaní
                Druh operačného systému (OS)
                Verzia Flash, podpora JavaScript, rozlíšenie obrazovky a schopnosť spracovania farieb na obrazovke
                Umiestnenie siete a IP adresa
                Sťahovania dokumentov
                Kliknutia na odkazy na externé webové stránky
                Chyby pri vypĺňaní formulárov používateľmi
                Kliknutia na videonahrávky
                Hĺbka skrolovania
                Interakcie s widgetmi špecifickými pre webovú stránku
                Informácie zhromažďujeme, aby sme internými procesmi analyzovali, ako návštevníci využívajú naše webové sídlo. Na základe tohto typu informácií dokážeme identifikovať fyzické osoby.
                <br />
                <br />
                2.4 Právnym základom spracovávania osobných údajov návštevníkov nášho webového sídla je náš oprávnený záujem spracovať potenciálnu žiadosť uchádzačov o pracovnú pozíciu v spoločnosti thiss s.r.o. a vylepšovať tak naše webové sídlo.
                <br />
                <br />
                2.5 Vykonávame náležité bezpečnostné opatrenia, aby sme zabránili zneužitiu osobných údajov návštevníkov a chránili tak bezpečnosť a integritu zhromažďovaných informácií.
                <br />
                <br />
                3 Uchovávanie osobných údajov
                <br />
                3.1 Vaše osobné údaje uchovávame tak dlho, ako je potrebné na splnenie účelov zhromažďovania osobných údajov. Keď osobné údaje už nie sú potrebné, sú vymazané.
                <br />
                <br />
                3.2 Osobné údaje môžu byť uchované, ak si to vyžadujú právne požiadavky.
                <br />
                <br />
                4 Príjemcovia osobných údajov
                <br />
                4.1 Spoločnosť thiss s.r.o. spolupracuje s inými organizáciami na poskytovaní služieb. Vaše osobné údaje odovzdávame organizáciám, ktoré musia spracovávať vaše osobné údaje v našom mene, aby napríklad mohli spravovať a zabezpečovať naše údaje, rozposielať e-maily alebo spracovať určité služby. Z tohto dôvodu môžeme poskytovať informácie týmto poskytovateľom služieb, napríklad IT dodávateľom.
                <br />
                <br />
                4.2 Tieto organizácie nesmú používať dané informácie na žiadne iné účely okrem tých, ku ktorým im pokynmi spoločnosti thiss s.r.o. boli dané k dispozícii.
                <br />
                <br />
                5. Informácie o právach dotknutých osôb
                <br />
                5.1 Ak naša spoločnosť spracováva vaše osobné údaje, ste ako dotknutá osoba oprávnená požiadať o prístup k nim. Ste oprávnený požadovať informácie, prečo osobné údaje zhromažďujeme a ako ich spracovávame, za akým účelom ich spracovávame, aký druh osobných údajov spracovávame, ktoré organizácie ich prijímajú a ako sú tieto osobné údaje uložené.
                <br />
                <br />
                5.2 Dotknutá osoba má právo dostať kópiu osobných údajov, ktoré poskytujeme bezplatne. Žiadosť o prístup k osobným údajom je potrebné podať v písomnej forme s Vaším vlastnoručným alebo elektronickým podpisom, ktorý spĺňa podmienky podľa všeobecne záväzných právnych predpisov platných na území Slovenskej republiky. Takúto žiadosť môžete podať aj prostredníctvom e-mailu support@thiss.sk a v takom prípade môžete požiadať o digitálnu kópiu.
                <br />
                <br />
                5.3 Máte právo požiadať o zrušenie Vášho súhlasu na použitie Vašich osobných údajov spoločnosťou thiss s.r.o. Kým nezrušíte Váš súhlas, neovplyvní to použitie vašich informácií.
                <br />
                <br />
                5.4 Dotknutá osoba je oprávnená požiadať o opravu nepresných osobných údajov, o obmedzenie ich spracovania a doplnenie neúplných osobných údajov. Máte tiež právo požiadať o vymazanie vašich osobných údajov, ak sú tieto informácie nesprávne alebo nezákonne spracovávané.
                <br />
                <br />
                5.5 Každý koho osobné údaje sa spracúvajú má právo podať sťažnosť týkajúcu sa spôsobu, akým sú jeho údaje spracúvané. Každý oprávnený obrátiť sa na na Úrad na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 4826/12, 820 07 Bratislava – mestská časť Ružinov.
                <br />
                <br />
                6. Súbory cookies
                <br />
                6.1 Spoločnosť thiss s.r.o. používa súbory cookies na prijímanie a zaznamenávanie informácií a na prijímanie informácií môžeme používať aj iné technológie („automatický zber údajov“). Môžu obsahovať aj osobné údaje z vášho prehliadača. Pri prijímaní týchto informácií sa riadime zákonom o elektronických komunikáciách č. 351/2011 Z.z. .
                <br />
                <br />
                6.2 Vo všeobecnosti sú súbory cookies malé súbory údajov, ktoré sú odosielané vášmu webovému prehliadaču vo vašom počítači, vo vašom mobilnom telefóne alebo v inom elektronickom zariadení, ktoré používate. Súbory cookies sa používajú na uloženie informácií v elektronickom zariadení a slúžia ako pamäť, čo znamená, že pri opätovnej návšteve webovej stránky je vaše zariadenie rozpoznané.
                Existujú dva druhy súborov cookies: dočasné a trvalé. Dočasné súbory cookie existujú iba dovtedy, kým nezatvoríte okno webového prehliadača vo vašom zariadení. Trvalé súbory cookie existujú dlhšie, po špecifikovaný čas.
                <br />
                <br />
                6.3 Informácie zhromaždené prostredníctvom súborov cookies môžu obsahovať nasledujúce:
                <br />
                <br />
                6.4 Spoločnosť thiss s.r.o. používa súbory cookies na získavanie informácií o tom, ako webovú stránku používate. Tieto informácie nám pomáhajú vylepšovať naše webové priestory a služby. Nastavenia vášho webového prehliadača je možné spravidla meniť, ak chcete vypnúť všetky súbory cookies, odmietnuť nové alebo dostávať varovné správy od vášho zariadenia v prípade odoslania nových súborov cookies.
                <br />
                <br />
                6.5 Ak má používateľ záujem upraviť súbory cookies, je potrebné použiť nastavenia prehliadača. Každý webový prehliadač je inak modifikovaný, používateľ prehliadača je oprávnený použiť Pomocníka v ponuke prehliadača a zistiť, ako zmeniť jeho súbory cookies. Zmenu súborov cookies musí používateľ vykonať v každom prehliadači, ktorý používa, ak má záujem o to, aby nastavenia týchto súborov boli rovnaké. Ak vypnete súbory cookies, je potrebné vziať na vedomie, že niektoré zo služieb na webových stránkach nemusia fungovať správne.
                <br />
                <br />
                6.6 Informácie, ktoré sa dajú použiť na identifikáciu používateľov webových stránok, nebudú dostupné pre tretie strany. Informácie, ktoré nesúvisia s totožnosťou používateľa, môžu byť zverejnené. Rozsah času, počas ktorého sú získavané informácie zo súborov cookies ukladané, závisí od toho, ako často webovú stránku používateľ navštevuje.
                <br />
                <br />
                6.7 Spoločnosť thiss s.r.o. používa na webových stránkach na svojej doméne www.thiss.tech , cookies tretích strán na analytické účely. Jedná sa o zhromažďovanie dát pomocou cookies pre Facebook Analytics, poskytovanú spoločnosťou Facebook a Google Analytics, poskytovanú spoločnosťou Google, Inc ., ktoré využíva informácie na účely vyhodnocovania používania stránky a vytvárania správ o aktivite návštevníka stránky. Zhromaždené dáta sa  vyhodnocujú v anonymnej podobe, vo forme štatistík, za účelom skvalitnenia služieb. Na základe poverenia prevádzkovateľom tejto stránky Facebook aj Google použije tieto informácie, aby vyhodnotil používanie stránky, vystavil reporty o aktivitách na stránke a aby prevádzkovateľovi stránky poskytol ďalšie služby spojené s jej používaním.
                Ukladaniu súborov cookies môžete zabrániť správnym nastavením vášho prehliadača. Zaznamenávaniu údajov cookies vzťahujúcich sa na vaše používanie stránky môžete zabrániť aj nainštalovaním modulu prehliadača prostredníctvom nasledujúceho odkazu: https://tools.google.com/dlpage/gaoptout?hl=en. Vyhlásenie Google o ochrane údajov je zverejnené na http://www.google.com/intl/sk/policies/privacy/.
                <br />
                <br />
                6.8 Cookies pre marketingové účely, ktoré spoločnosť thiss s.r.o. využíva, ukladajú Informácie o stránkach na našom webe www.thiss.tech , ktoré ste navštívili a reklamné kampane, ktoré ste videli a na ktoré ste klikli. On-line reklamné sledovacie súbory cookies vám zobrazujú on-line reklamy na základe toho, čo ste si pozreli a na čo ste klikli, a tiež na základe stránok nášho webového sídla, ktoré ste navštívili. Poskytovateľom je Facebook https://www.facebook.com/policy.php pomocou Facebook Pixel a Google, Inc. https://www.google.com/policies/privacy/ pomocou Google Ads.[LT3] 
                V prípade akýchkoľvek ďalších otázok, týkajúcich sa spracúvania Vašich osobných údajov nás neváhajte kontaktovať prostredníctvom formulára na našom webovom sídle, ktorý nájdete v sekcii spracovanie osobných údajov, alebo prostredníctvom e-mailu na support@thiss.tech.
                </p>
        </div>
    </div>
  )
}
