import React from 'react'
import './CennikMenu.scss'
import HandShake_Icon from '../../../resources/handshake_icon.webp'
import Email_Icon from '../../../resources/email_icon.webp'

export default function CennikMenu() {
  return (
    <section id='cennik-menu'>
      <div className="cennik-menu-title">
          <h1>Vyberte si z ponuky</h1>
      </div>
      <div className="cennik-menu-select">

        <div className="cennik-menu-option" onClick={() => {window.location.href = "/cennik/sla"}}>
          <div className="cennik-menu-option-img">
            <img src={HandShake_Icon} alt="Img" />
          </div>
          <div className="cennik-menu-option-title">
            <h2>Služby podla SLA</h2>
          </div>
          <div className="cennik-menu-option-description">
              <p>Správu IT infraštruktúry poskytujeme na základe SLA zmluvy, v ktorej vám garantujeme reakčné časy podľa Vašich potrieb a v ktorej vieme garantovať aj časy riešenia vašich problémov.</p>
          </div>
        </div>

        {/* Email */}
        <div className="cennik-menu-option" onClick={() => {window.location.href = "/cennik/email"}}>
          <div className="cennik-menu-option-img">
            <img src={Email_Icon} alt="Img" />
          </div>
          <div className="cennik-menu-option-title">
            <h2>Email Služby</h2>
          </div>
          <div className="cennik-menu-option-description">
              <p>Všetky Vaše firemné úlohy, e-maily, kontakty a poznámky vždy pokope? Naša služba zabezpečeného mailu pre firmy je tu pre Vás</p>
          </div>
        </div>
      </div>
    </section>
  )
}