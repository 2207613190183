import React from 'react'
import DEV from '../const/DEV/DEV'
import Popup from '../const/popup/Popup'
import Header from '../const/header/Header'
import Landing from '../const/landing/Landing'
import VideoShow from '../const/videoshow/VideoShow'
import AboutUs from '../const/aboutus/AboutUs'
import WWD from '../const/wwd/WWD'
import Partners from '../const/partners/Partners'
import Footer from '../const/footer/Footer'
import CookiesPopUp from '../const/cookiespopup/CookiesPopUp'

export default function LandingPage() {
  return (
    <div>
      <CookiesPopUp/>
      <Popup/>
      <Header/>
      <Landing/>
      <VideoShow/>
      <AboutUs/>
      <WWD/>
      <Partners/>
      <Footer/>
    </div>

  )
}
