import React from 'react'
import Footer from '../const/footer/Footer'
import Header from '../const/header/Header'
import Popup from '../const/popup/Popup'
import ToS from '../const/ToS/ToS'


export default function ToSBuilder() {
  return (
    <div>
        <Popup/>
        <Header/>
        <ToS/>
        <Footer/>
    </div>
  )
}
