import {React, useState, useEffect} from 'react'
import { Navigate } from "react-router-dom";
import './ContactBody.scss'
import axios from 'axios';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Partners from '../partners/Partners'
import Certifikat from '../cert/Certifikat';



export default function ContactBody() {
    const [data, setData] = useState({
        email: "",
        fname: "",
        lname: "",
        subject: "",
        isgdbrChecked: false
    });

    const handleChange = (e) => {
        if(e.target.type === 'checkbox') { 
            setData(prev => ({...prev, [e.target.name]: e.target.checked}))
        }
        else { 
            setData(prev => ({...prev, [e.target.name]: e.target.value}))
        }
    }

    const handleSubmit = (e) => {

        axios.post(`${process.env.REACT_APP_VM_URL}/mail/contact`, {
          email: data.email,
          fname: data.fname,
          lname: data.lname,
          subject: data.subject
        })
        .then(function (response) {
          window.location.href = '/kontakt'
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    useEffect(() => {
    },[data])


    return (
        <section id='contact'>
            <div className="contact-container">
                <div className="contact-left">
                    <div className="contact-left-block">
                        <h2><i className="fa-solid fa-location-dot"></i> <br /> Kde nás nájdeš ?</h2>
                        <div className="contact-block-info">
                            <a href='https://goo.gl/maps/cU2qkkGgSSUZ9FE68'>Mlynské Nivy 56 821 05 Bratislava Slovenská republika</a>
                        </div>
                    </div>
                    <div className="contact-left-block">
                        <h2><i className="fa-solid fa-phone"></i> <br /> Tu nám zavolaj</h2>
                        <div className="contact-block-info">
                            <p>PO-PI 9:00 - 17:00</p>
                            <a href = "tel: +421 914 222 000">+421 914 222 000</a>
                        </div>
                    </div>
                    <div className="contact-left-block">
                        <h2><i className="fa-solid fa-user-group"></i> <br /> Pridaj sa k nám</h2>
                        <div className="contact-block-info">
                            <a target='_blank' href="https://www.instagram.com/thiss.tech/">INSTAGRAM</a>
                            <a target='_blank' href="https://www.facebook.com/thiss.tech/">FACEBOOK</a>
                        </div>
                    </div>
                </div>
                <div className="contact-form">
                    <form method="POST" action="/contact">
                        <div className="contact-form-title">
                            <h1>Napíšte nám</h1>
                        </div>
                        <div className="contact-form-inputs">
                            <input minLength="3" type="text" name='fname' required placeholder='*Meno' onChange={handleChange} />
                            <input type="email" placeholder='*Email' name='email' onChange={handleChange} title='Ukazka: example@example.com' required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></input>
                            <div className="contact-textarea">
                                <textarea required spellCheck="false" name="subject" onChange={handleChange} id="" cols="30" placeholder='*Napíšte nám' rows="10"></textarea>
                                <div className="popup-container-submit">
                                <button type='button' onClick={handleSubmit} disabled={data.isgdbrChecked === true ? false : true } id='popup-submit-button'>ODOSLAŤ</button>
                            </div>
                            <div className="gdpr">
                                <input onChange={handleChange} name="isgdbrChecked" id='accept-gdpr' type="checkbox" />
                                <label htmlFor="accept-gdpr">Súhlasím so spracovaním <a href='/personal-data'>osobných údajov</a></label>
                            </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}