import React from 'react'
import './AboutUs.scss'
import squid from '../../resources/squid_videoshow_background.webp'
import money_paper from '../../resources/money_paper.png' 
import know_how from '../../resources/know_how.webp' 

export default function AboutUs() {
  return (
    <section id='AboutUs'>
        <div className="aboutus-title">
            <h1>S našim IT supportom skvelo dopĺňame naše IT služby.</h1>
            <p>Sme odborníkmi pre IT služby, správu IT, IT bezpečnosť, Audit IT a Cloudové služby.</p>
        </div>
        <div className="aboutus-content">

            {/* Main Card */}
            <div onClick={() => {window.location.href = "/kontakt"}} className="aboutus-card aboutus-header-card">
                <div className="aboutus-header-card-content">
                    <div className="aboutus-card-title">
                        <h2>Máte strach o vaše firemné údaje?</h2>
                    </div>
                    <div className="aboutus-card-desc">
                        <p>Súčasťou IT služieb našik zákazníkom ponúkame support-servis vždy v pracovné dni od 9:00-17:00 avšak ak máte záujem túto dobu predĺžiť alebo zmeniť kontaktujte nás a navrhneme vám riešenie na mieru.</p>
                    </div>
                    <div className="aboutus-card-button">
                        <button>Zistiť viac <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
                <img className='aboutus-squid' src={squid} alt="" />
            </div>


            {/* Second Card */}
            <div onClick={() => {window.location.href = "/cennik"}} className="aboutus-card aboutus-another">
                <div className="aboutus-card-content">
                    <div className="aboutus-card-title">
                        <h2 className='dark'>Naše IT služby sú vždy orientované na zákazníka</h2>
                    </div>
                    <div className="aboutus-card-desc">
                        <p className='dark'>Znížime Vám náklady na IT služby, zvýšime efektivitu vašej práce a taktiež sa budeme snažiť zvýšiť zisk. Ako IT firma pristupujeme k zákazníkom s profesionálnym prístupom a pomôžeme vám s rozvojom IT infraštruktúry. </p>
                    </div>
                    <div className="aboutus-card-button-sub">
                        <button className='dark'>Zistiť viac <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
                <img style={{right: "0"}} className='aboutus-another-img' src={money_paper} alt="" />
            </div>

            {/* Third Card */}
            <div onClick={() => {window.location.href = "/sluzby/domov"}} className="aboutus-card aboutus-another">
                <div className="aboutus-card-content">
                    <div className="aboutus-card-title">
                        <h2 className='dark'>Čo je pre nás dôležité?</h2>
                    </div>
                    <div className="aboutus-card-desc">
                        <p className='dark'>Technológia a know-how sú našim prostiredkom, riešenie je náš cieľ. Ak hľadáte správcu siete na profesionálnej úrovni, ste na správnej adrese. </p>
                    </div>
                    <div className="aboutus-card-button-sub">
                        <button className='dark'>Zistiť viac <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
                <img className='aboutus-another-img' src={know_how} alt="" />
            </div>
        </div>
    </section>
  )
}
