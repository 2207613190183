import { useState } from 'react';
import { useParams } from 'react-router';

// This is children of SluzbyBody
export default function CreateSluzby( {currentData} ) {

    const html = () => { 
        return {__html: currentData.subject};
    }
    
    const GetContent = () => { 
        return (
            <div className='sluzby-content-data'>
                <div className='react-quill-content' dangerouslySetInnerHTML={html()} />
            </div>
        )
    }

    return (
        <section id='sluzby-site'>
                <div className="sluzby-body">
                    {
                        currentData ? <>
                        <div className="sluzby-header">
                            <h1>{currentData?.title}</h1>
                            <div className="sluzby-header-info">
                                <div className="sluzby-info-text">
                                    {currentData?.author ? <p>Autor: {currentData?.author}</p> : null }
                                    {currentData?.create_date ? <p>Datum Vytvorenia: {currentData.create_date}</p> : null }
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="sluzby-content">
                            <GetContent/>
                        </div></> : 
                        <div className="sluzby-domov-title">
                            <h1>Aké služby ponúkame ?</h1>
                            <p>Vyberte si z menu</p>
                        </div>
                    }
            </div> 
        </section>
    )
}
