import React from 'react'
import { useState,  useEffect} from 'react'
import { Link } from 'react-router-dom'
import ApiCennik from './ApiCennik'
import './CennikBody.scss'


export default function EmailCennikBody() {
    const [list, setList] = useState([])
    const [index, setIndex] = useState(5)
    const [isReady, setReady] = useState(false)

    const fetchSLA = async () => {
        fetch(`${process.env.REACT_APP_VM_URL}/api/email`)
        .then(res => res.json())
        .then(data => {
            setList(data)
        })
        .catch(error => console.error(error))
    } 

    useEffect(() => {
        fetchSLA();
    }, [])

    useEffect(() => {
        if(list.length > 0) { 
            setReady(true)
        }
    }, [list])

    const apiCennikCB = (currentIndex) => {
        setIndex(prev => currentIndex)
    }
    
    return (
        <section id='cennik'>
            <h1 className='cenova-ponuka'>thiss e-mailová služba</h1>
            <p className='cennik-sla-info-title'>Naša e-mailová služba dokáže synchornizovať všetký vami pripojené zariadenia.Takisto je to jednoduchá voľba bez ohľadu na to či ste malá, stredná alebo veľká firma. Jednoduchosť, spoľahlivosť, rýchosť a hlavne zabezpečenie všetkých Vašich mailov. Šifrovanie mailov na vysokej úrovni je prvoradá vec, ktorú naša mailová služba dodržiava. Anonymity sa pri všetkých e-mailoch nemusíte báť, dbáme na anonymitu našich zákazníkov a taktiež na anonymitu a šifrovanie všetkých ich osobných údajov.</p>
            <div className="cennik-email-redict">
                <Link to="/cennik/sla">
                    <h5>Možno máte záujem o SLA služby ?</h5>
                </Link>
            </div>
            {isReady? <ApiCennik callbackData={apiCennikCB} data={list}/> : ""}
            <p className='cennik-info'>*Bojíte sa prechodu z Vášho e-mailového riešenia ? Synchronizácia s Vaším Outlookom? Bezproblémov! Váš kalendár a všetko to, čo ste použili spolu s outlookom Vám pomôžeme prepojiť s našim e-mailovým riešením.</p>
            <button onClick={() => {window.location.href = `/cennik/continue/email/${index}/${list[index].url}`}} className='cennik-continue' disabled={index === 5? true : false}>Pokracuj</button>
        </section>
    )
}