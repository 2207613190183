import React, { useState } from 'react';
import './GDPRBody.scss'

const GDPRBody = ({onClick}) => {
    const [data, setData] = useState({
        isgdbrChecked: false
    });

    const handleChange = (e) => {
        if(e.target.type === 'checkbox') { 
            setData(prev => ({...prev, [e.target.name]: e.target.checked}))
        }
    }

    return (
        <div className='gdpr'>
            <input onChange={handleChange} onClick={() => onClick(data.isgdbrChecked)} name="isgdbrChecked" id='accept-gdpr' type="checkbox" />
            <p htmlFor="accept-gdpr">Súhlasím so spracovanim <a href='/personal-data'>osobnych údajov</a></p>
        </div>
    );
}
export default GDPRBody;
