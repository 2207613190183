import React from 'react'
import Header from '../const/header/Header'
import Footer from '../const/footer/Footer'
import Onas from '../const/onas/Onas'

export default function OnasBuilder() {
  return (
    <>
    <Header/>
    <Onas/>
    <Footer/>
    </>
  )
}
