import React from 'react'
import './Certifikat.scss'

import iso_9001 from '../../resources/ISO_9001-scaled.jpg'
import iso_27001 from '../../resources/ISO_27001-scaled.jpg'

import preveiw_iso_9001 from '../../resources/preview-ISO_9001.png'
import preview_iso_27001 from '../../resources/preview-ISO_27001-1.png'

export default function Certifikat() {
  return (
    <>
    <div className="contact-cert">
        <div className="contact-cert-title">
            <h2>Naše certifikáty</h2>
        </div>
        <div className="contact-cert-content">
            <a href={iso_9001}>
                <img src={preveiw_iso_9001} />
            </a>
            <a href={iso_27001}>
                <img src={preview_iso_27001}/>
            </a>    
        </div>
    </div>
    </>
  )
}
