import React from 'react'
import './Slacomplete.css'

export default function SlaCompleteBody() {


    

  return (
    <section id='slaComplete'>
        <div className="slaComplete-container">
            <div className="slaComplete-content">
                <h1>Skontrolujte si vašu emailovú schránku</h1>
            </div>
            <div className="slaComplete-content">
                <p>Kliknutim na tlacidko "Pokračovať" sa dostanete späť na hlavnú stránku</p>
            </div>
            <div className="slaComplete-content">
                <button onClick={() => {window.location.href = "/"}}>Pokračovať</button>
            </div>
        </div>
    </section>
  )
}
