import React from 'react'
import SLA from '../const/sla/SLA'
import DEV from '../const/DEV/DEV'
import Footer from '../const/footer/Footer'
import Header from '../const/header/Header'
import Popup from '../const/popup/Popup'


export default function OrderSla() {
  return (
    <>
        <Popup/>
        <Header/>
        <SLA/>
        <Footer/>
    </>
  )
}
