import React from 'react'
import Footer from '../const/footer/Footer'
import Header from '../const/header/Header'
import Popup from '../const/popup/Popup'
import PersonalData from '../const/personalData/PersonalData'

export default function PersonalDataBuilder() {
  return (
    <>
        <Popup/>
        <Header/>
        <PersonalData/>
        <Footer/>
    </>
  )
}
