import React from 'react'
import './ToS.scss'

export default function ToS() {
  return (
    <div className='tos'> 
        <div>
            <h1>Všeobecné obchodné podmienky</h1>
        </div>
        <div>
            <p>
                <br />
                Tieto Všeobecné obchodné podmienky (ďalej len “obchodné podmienky” alebo “VOP”) upravujú
                práva a povinnosti spoločnosti thiss s.r.o., so sídlom Mlynské Nivy 56, 821 05 Bratislava, IČO: 47 669 217, zapísaná v Obchodnom registri Okresného súdu Bratislava I, oddiel Sro, vložka č.: 122069/B (ďalej len “dodávateľ”) vo vzťahu k svojím zákazníkom (ďalej len “odberateľ”).
                Článok I Všeobecné ustanovenia
                <br />
                <br />
                1.1. Všetky zákazky, t.j. objednávky, zmluvy alebo dohody sú právne záväzné len vtedy, ak sú vyhotovené v písomnej forme a opatrené riadnym podpisom oprávnených osôb.
                <br />
                <br />
                1.2. Objednávky od odberateľa sú záväzné, aj keď sú dodávateľovi zaslané elektronicky (Takáto objednávka sa považuje za záväznú a je v súlade so zákonom č. 108/2000 Z.z. v znení neskorších zmien a predpisov, chápaná ako zmluva uzatváraná na diaľku).
                <br />
                <br />
                1.3. Pri každej objednávke musí odberateľ uviesť meno a priezvisko, poštovú a fakturačnú adresu,
                telefónne a faxové číslo, e mail, právnická osoba – podnikateľ aj IČO, DIČ, IČ DPH, názov tovaru, popis tovaru, služby, počet kusov alebo merných jednotiek, dátum vystavenia objednávky.
                <br />
                <br />
                1.4. Ponuky dodávateľa na poskytované služby, produkty alebo tovary sú zásadne nezáväzné a môžu byť zaslané aj elektronicky.
                <br />
                <br />
                1.5. Pre obchod alebo zmluvný vzťah medzi odberateľom a dodávateľom platia výlučne tieto obchodné podmienky.
                <br />
                <br />
                1.6. Všeobecné obchodné podmienky odberateľa, ktoré sú v rozpore s týmito obchodnými
                podmienkami sú neplatné a neúčinné. Platia len v prípade, ak sú písomne odsúhlasené oboma zmluvnými stranami.
                <br />
                <br />
                1.7. V prípade, že jeden z bodov týchto obchodných podmienok stratí účinnosť, účinnosť ostatných bodov zostane nedotknutá.
                <br />
                <br />
                1.8. Obchodné podmienky sú dostupné v sídle dodávateľa a okrem toho tvoria súčasť každej zmluvy alebo dohody uzatvorenej medzi odberateľom a dodávateľom. Tiež sú dostupné na internetovej stránke www.thiss.tech.
                <br />
                <br />
                Článok II
                Poskytované služby a produkty
                <br />
                <br />
                2.1 Predmetom obchodného prípadu – objednávky alebo zmluvy sú produkty, služby alebo tovary
                zodpovedajúce predmetu činnosti dodávateľa.
                <br />
                <br />
                2.2 Rozsah a špecifikácia služieb, produktov a tovarov poskytnutých odberateľovi je definovaná samostatne pre každú jednotlivú zákazku, obchodný prípad.
                <br />
                <br />
                2.3 Dodávateľ môže tovar alebo služby vyplývajúce z objednávky zabezpečiť aj cez tretiu stranu.
                <br />
                <br />
                2.4 Úhrada za služby, produkty alebo tovary tretích strán prislúcha výlučne dodávateľovi.
                <br />
                <br />
                2.5 V prípade, že predmetom objednávky je služba, odberateľ sa zaväzuje počas doby trvania zmluvného vzťahu s dodávateľom, ako aj dva (2) roky po skončení tohto zmluvného vzťahu,
                nevstupovať do obchodného vzťahu s fyzickými či právnickými osobami, ktorých služby dodávateľ využíva na splnenie svojich zmluvných záväzkov voči odberateľovi. Odberateľ sa taktiež zaväzuje, že nepožiada tieto fyzické alebo právnické osoby o zákazky, ktorých predmetom sú služby, ktoré sú v ponuke dodávateľa. V prípade porušenia tejto povinnosti má dodávateľ právo požadovať od
                odberateľa úhradu zmluvnej pokuty vo výške 30.000,- EUR. Zmluvná pokuta je splatná porušením povinnosti stanovenej v tomto bode VOP zo strany odberateľa a jej uhradením nie je dotknuté právo dodávateľa na náhradu škody presahujúcej zmluvnú pokutu.
                <br />
                <br />
                2.6 V prípade služieb je odberateľ povinný vytvárať dodávateľovi podmienky pre výkon služby. Odberateľ je povinný reagovať na výzvy dodávateľa k spolupráci, zabezpečiť včasný prísun všetkých relevantných informácií a informovanosť všetkých dotknutých zamestnancov alebo zodpovedných orgánov. V opačnom prípade, a teda, ak odberateľ neposkytne dodávateľovi potrebnú súčinnosť, dodávateľ nezodpovedá za splnenie služieb v dohodnutom termíne a obsahu.
                <br />
                <br />
                2.7 Poskytnutie individuálnych služieb, vypracovanie individuálnych programov prebieha na základe informácií, podkladov a prostriedkov poskytnutých odberateľom. Odberateľ zodpovedá za včasné poskytnutie týchto informácií, podkladov a prípadných služieb. Náklady s poskytnutím týchto služieb nesie v plnom rozsahu odberateľ, a to nad rámec dohodnutej ceny služby.
                <br />
                <br />
                2.8 Podkladom pre vytvorenie individuálneho software, prípadne služby je písomný opis
                funkcií alebo činností, ktorý podľa poskytnutých informácií a podkladov za dohodnutú odplatu vypracuje alebo vykoná, resp. bude vykonávať dodávateľ. Odberateľ ho preverí vzhľadom na jeho správnosť a úplnosť, a svojim súhlasom potvrdí jeho platnosť. Neskoršie zmeny, úpravy alebo dodatky môžu viesť k novým dohodám vzhľadom na cenu a termíny.
                <br />
                <br />
                2.9 Individuálny software, jeho časť alebo rozšírenie musí byť odberateľom prevzatý najneskôr do dvoch týždňov od ich dodania. Ak táto lehota uplynie, je software považovaný za prevzatý. Software je považovaný za prevzatý aj v prípade, že je odberateľom uvedený do prevádzky.
                <br />
                <br />
                2.10 Za chyby, nedostatky v software sú považované odchýlky v porovnaní s opisom funkcií. Tieto musia byť odberateľom riadne zdokumentované a odovzdané dodávateľovi. Ak sa jedná o kritické nedostatky (neumožňujúce prevádzku), je potrebné opätovne vykonať proces prevzatia. Odberateľ nie je oprávnený odmietnuť prevzatie software kvôli drobným nedostatkom.
                <br />
                <br />
                2.11 Objednaním hotových programov odberateľ potvrdzuje znalosť ich rozsahu a funkčnosti.
                <br />
                <br />
                Článok III
                Ceny, poplatky a platobné podmienky
                <br />
                <br />
                3.1 Všetky ceny sú uvádzané v eurách (EUR) bez dane z pridanej hodnoty. Daň z pridanej hodnoty sa účtuje podľa platnej legislatívy SR.
                <br />
                <br />
                3.2 Všetky ceny sú stanovené dohodou oboch zmluvných strán.
                <br />
                <br />
                3.3 Vystavené faktúry dodávateľom sú splatné podľa dátumu splatnosti na faktúre a splatnosť je určená dohodou oboch zmluvných strán pre danú zákazku.
                <br />
                <br />
                3.4 Pri objednávkach produktov a tovarov, ktoré sa skladajú z viacerých častí (napr. programy, školenia, služby alebo tovary po častiach) má dodávateľ právo vystaviť faktúru po dodaní každej jednej časti, pokiaľ nie je dohodnuté inak.
                <br />
                <br />
                3.5 Dodržiavanie dohodnutých platobných termínov je podstatnou podmienkou pre uskutočnenie dodania alebo naplnenia zmluvy zo strany dodávateľa. Nedodržanie dohodnutých platieb oprávňuje dodávateľa pozastaviť prácu a odstúpiť od zmluvy. Odberateľ sa zaväzuje uhradiť všetky s tým spojené náklady a ušlý zisk. Pri oneskorených platbách budú účtované úroky z omeškania podľa platnej legislatívy.
                <br />
                <br />
                3.6 Odberateľ nie je oprávnený zadržiavať platby z dôvodu neúplného dodania alebo uplatňovania záruky, či reklamácie.
                <br />
                <br />
                Článok IV Dodacie podmienky
                <br />
                <br />
                4.1 Dodávateľ sa zaväzuje vyvíjať maximálne úsilie o dodržanie dohodnutých termínov dodania služby alebo tovaru. Dohodnutý termín dodania sa predlžuje o dni, v ktorých nebude možné
                pokračovať v plnení objednávky z objektívnych dôvodov zapríčinených okolnosťami vyššej moci alebo inými nepredvídateľnými udalosťami, ktoré predávajúci nemá možnosť ovplyvniť. Dodávateľ oznámi takéto okolnosti odberateľovi do troch (3) dní od ich vzniku a ich zánik do troch (3) dní od ich
                ukončenia. Tomuto režimu podlieha tiež nemožnosť riadneho postupu plnenia zákazky pre prekážky na strane odberateľa.
                <br />
                <br />
                4.2 Dodržať dodacie termíny je možné len v prípade, že odberateľ k určenému termínu dodá všetky potrebné informácie i podklady a zároveň aktívne spolupracuje v najvyššej možnej miere. K
                podkladom patrí napríklad: potvrdenie opisu funkcií software, popis služby, tovaru a pod.
                <br />
                <br />
                4.3 Pri zákazkách, ktoré sa skladajú z viacerých častí alebo programov má dodávateľ právo dodávať a fakturovať po častiach.
                <br />
                <br />
                Článok V
                Autorské a užívateľské práva
                <br />
                <br />
                5.1 Autorské práva týkajúce sa dohodnutých diel prináležia výlučne dodávateľovi, prípadne poskytovateľovi licencií. Odberateľ získa výlučne len právo užívať software alebo výsledok poskytnutej služby na dohodnutý účel a v dohodnutom rozsahu, pokiaľ sa obe zmluvné strany nedohodnú inak.
                <br />
                <br />
                5.2 Šírenie diela odberateľom je neprípustné.
                <br />
                <br />
                5.3 Spoluúčasťou pri vývoji diela odberateľ nezískava žiadne autorské práva.
                <br />
                <br />
                5.4 Pri porušení autorských práv má dodávateľ právo si nárokovať náhradu spôsobených škôd.
                <br />
                <br />
                Článok VI Právo na odstúpenie
                <br />
                <br />
                6.1 Dodávateľ je oprávnený odstúpiť od zmluvy uzavretej s odberateľom z dôvodu neposkytnutia potrebnej súčinnosti zo strany odberateľa podľa čl. II, bodu 2.6 VOP, a to za podmienky, že kupujúci
                neposkytne požadovanú súčinnosť ani po opakovanej písomnej výzve dodávateľa. V takomto prípade má dodávateľ nárok na úhradu 50 % z dohodnutej hodnoty zákazky.
                <br />
                <br />
                6.2 Prírodné katastrofy alebo iné objektívne okolnosti, na ktoré dodávateľ nemá vplyv, zbavujú dodávateľa jeho dodacích záväzkov a umožňujú mu určenie novej dodacej lehoty.
                <br />
                <br />
                6.3 Stornovanie záväzných objednávok zo strany odberateľa je možné len po písomnom súhlase dodávateľa. Ak dodávateľ súhlasí so stornovaním, má okrem zaplatenia vynaložených nákladov nárok na stornovací poplatok vo výške 30 % zo zatiaľ nevyúčtovanej hodnoty objednávky.
                <br />
                <br />
                Článok VII Záruka
                <br />
                <br />
                7.1 Dodávateľ má právo a povinnosť odstrániť objavené nedostatky na dodanej službe,
                produkte alebo, ktoré mu odberateľ bezodkladne po ich zistení písomne alebo preukázateľne elektronicky oznámi.
                <br />
                <br />
                7.2 Reklamácie na software a služby sú platné, ak sa týkajú opakovaných nedostatkov, a ak sú uskutočnené do šiestich (6) mesiacov po ukončení projektu alebo odovzdaní služby. V prípade dodávky tovaru sa poskytuje záruka podľa platného Obchodného zákonníka. V prípade záruky má oprava prednosť pred znížením ceny alebo výmenou chybného produktu za bezchybný. Oprávnené reklamácie budú spracované za primeranú dobu. Odberateľ sa pritom zaväzuje poskytnúť dodávateľovi všetky dostupné informácie a prostriedky potrebné pre odstránenie nedostatkov.
                <br />
                <br />
                7.3 Organizačné a programové chyby alebo nedostatky, za ktoré nesie zodpovednosť dodávateľ, budú odstránené na náklady dodávateľa.
                <br />
                <br />
                7.4 Náklady za služby pri nesprávnom určení chyby alebo za odstraňovanie chýb a nedostatkov, za ktoré nesie zodpovednosť odberateľ mu budú vyúčtované.
                <br />
                <br />
                7.5 V prípade, že príde ku neoprávnenej manipulácii s dielom alebo tovarom (doplnenia, zmeny, používanie v rozpore s manuálom a pod.) zamestnancami odberateľa alebo tretími osobami, dodávateľ je zbavený povinnosti poskytovať na službu, produkt alebo tovar záruku.
                <br />
                <br />
                7.6 Ak je predmetom objednávky alebo zmluvy zmena alebo doplnenie už existujúcich diel, vzťahuje sa záruka len na tieto zmeny a doplnenia, v žiadnom prípade nie na celé dielo.
                <br />
                <br />
                VIII
                Zodpovednosť za škody
                <br />
                <br />
                8.1 Dodávateľ ručí odberateľovi za všetky škody, ktoré boli spôsobené hrubým zavinením (úmysel alebo hrubá nedbanlivosť).
                <br />
                <br />
                8.2 Odberateľ musí dokázať, že škoda bola zavinená dodávateľom.
                <br />
                <br />
                8.3 Nárok na úhradu vzniknutej škody musí byť súdne uplatnený do šiestich (6) mesiacov od odhalenia škody, najneskôr však do jedného (1) roka od udalosti, ktorej sa nárok týka.
                <br />
                <br />
                8.4 Náhrada za vzniknuté škody, škody na majetku, ušlého zisku a úrokov je vylúčená.
                <br />
                <br />
                8.5 V prípade, že sa nárok týka časti diela, ktoré bolo vyhotovené treťou stranou, dodávateľ tento nárok postúpi ďalej odberateľovi. Odberateľ sa pri uplatňovaní tohto nároku obráti priamo na tretiu stranu.
                <br />
                <br />
                Článok IX
                Zachovanie obchodného tajomstva a ochrana údajov
                <br />
                <br />
                9.1 Dodávateľ sa zaväzuje zachovať mlčanlivosť o všetkých obchodných záležitostiach, hlavne o
                obchodných a podnikových tajomstvách, ku ktorým sa v priebehu spolupráce s odberateľom dostane.
                <br />
                <br />
                9.2 Dodávateľ je zbavený mlčanlivosti voči zástupcom a pomocníkom, ktorí na diele spolupracujú. Je však povinný zaviazať ich rovnakou mlčanlivosťou a preberá za ich mlčanie plnú zodpovednosť.
                <br />
                <br />
                9.3 Povinnosť mlčať je časovo neobmedzená.
                <br />
                <br />
                9.4 Dodávateľ má právo, využívať jemu zverené osobné údaje na účely definované zmluvným
                vzťahom pod podmienkou, že tieto údaje budú podľa zákona o ochrane osobných údajov č. 428/2002
                Z.z. použité iba v rámci obchodného vzťahu medzi dodávateľom a odberateľom a nebudú poskytnuté tretej osobe (s výnimkou osoby zabezpečujúcej prepravu tovaru).
                <br />
                <br />
                9.5 Odberateľ ručí dodávateľovi, že vo vzťahu k dotknutým osobám sú splnené všetky predpoklady pre užívanie týchto údajov, podľa zákona o ochrane osobných údajov.
                <br />
                <br />
                Článok X Záverečné ustanovenia
                <br />
                <br />
                10.1 Zmluva medzi dodávateľom a odberateľom platí do ukončenia projektu, ukončenia poskytovania služby alebo dodaním produktu alebo tovaru.
                <br />
                <br />
                10.2 Bez ohľadu na ukončenie poskytovania služby, projektu alebo dodania tovaru môže byť zmluva ukončená kedykoľvek bez dodržania výpovednej lehoty a to v prípade:
                – ak zmluvný partner poruší dôležité zmluvné záväzky,
                – ak je voči zmluvnému partnerovi začaté konkurzné konanie,
                – ak sa na tom dohodnú obe zmluvné strany.
                <br />
                <br />
                10.3 Zmluvní partneri sa môžu v zmluve dohodnúť, že priamo alebo cez tretie strany
                nevstúpia do pracovného pomeru so zamestnancami dodávateľa počas zmluvného vzťahu a tiež dvanásť (12) mesiacov po ukončení tohto vzťahu. Ak odberateľ uvedené stanovisko poruší, zaväzuje sa zaplatiť dodávateľovi paušálnu náhradu škody vo výške celoročného príjmu zamestnanca.
                <br />
                <br />
                10.4 Dodávateľ sa môže s odberateľom dohodnúť na zaslaní faktúry elektronickou poštou.
                <br />
                <br />
                10.5 Všetky zmeny VOP musia byť vykonané písomne. Zmenené alebo doplnené obchodné podmienky vstupujú do platnosti po podpise konateľa a ich následným zverejnením na stránke www.thiss.tech
                <br />
                <br />
                10.6 Ponuky dodávateľa a objednávky odberateľa môžu byť zasielané aj elektronicky. Ústne dohovory sú neplatné. Všetky zmeny zmlúv medzi oboma stranami musia byť dohodnuté len
                písomne. Pre každú zmluvu platia VOP platné v deň podpisu zmluvy, resp. v čase zaslania záväznej objednávky odberateľa dodávateľovi.
                <br />
                <br />
                10.7 Práva a povinnosti odberateľa a dodávateľa neupravené týmito VOP sa riadia príslušnými ustanoveniami Obchodného zákonníka.
                <br />
                <br />
                10.8 Právne vzťahy medzi dodávateľom a odberateľom sa spravujú slovenským právnym
                poriadkom, a to aj v prípade, že odberateľ nie je občanom SR, nemá na území SR sídlo, bydlisko, ako aj v prípade, že služba sa poskytuje mimo územia SR.
                <br />
                <br />
                10.9 Strany majú záujem riešiť svoje spory prednostne mimosúdne a preto sa podľa zákona č.
                <br />
                <br />
                244/2002 Z.z. (ďalej len “ZRK”) dohodli, že okrem tých sporov, pri ktorých to zákon výslovne vylučuje, predložia všetky spory, ktoré medzi nimi vznikli alebo vzniknú v súvislosti s uzavretou zmluvou, ako aj spory o platnosti, trvania a výkladu Zmluvy a právnych úkonov strán, o náhradu škody a vydanie
                bezdôvodného obohatenia, o iných nárokoch strán, ktoré vzniknú za trvania Zmluvy, najmä o nárokoch, ktoré so Zmluvou priamo súvisia) zo vzťahov čo aj nepriamo súvisiacich so Zmluvou alebo zmluvu zabezpečujúcich (napr. ručenie), na rozhodnutie Slovenskému rozhodcovskému súdu – stálemu rozhodcovskému súdu, zriadenému zriaďovateľom: Slovenská asociácia rozhodcovských súdov z.z.p.o., IČO 37 888 307 (ďalej len “SRS”).
                Ak je zmluvnou stranou fyzická osoba, ktorú osobitný predpis považuje za spotrebiteľa, je táto
                oprávnená podať žalobu nielen na SRS, ale aj na všeobecnom súde, to však iba do doby, kým nebola niektorou stranou na SRS podaná akákoľvek žaloba, ktorej predmetom je spor vymedzený v
                predchádzajúcej vete, právo z tejto Zmluvy alebo právo so Zmluvou súvisiace. Podaním takej žaloby na SRS ktoroukoľvek stranou je daná výlučná právomoc SRS rozhodovať všetky spory strán uvedené vyššie a právomoc všeobecného súdu je tým vylúčená. Strany vyhlasujú, že sa rozhodnutiu SRS bez výhrad podriadia. Strany vylučujú podanie žaloby podľa § 40 ods. 1 písm. h) ZRK (dôvody obnovy
                konania) a dohodli sa, že SRS bude konať nasledovne: podľa slovenského právneho poriadku, podľa zásad spravodlivosti, pokiaľ to zákon nevylučuje, podľa rokovacieho poriadku a štatútu SRS,
                zverejneného v čase začatia konania v Obchodnom vestníku, konanie bude písomné rozhodcu (senát) vymenuje SRS. Tieto Všeobecné obchodné podmienky boli schválené spoločnosťou thiss s.r.o. dňa 01.01.2019. Súčasne rušia a plne nahradzujú predchádzajúce znenie VOP.
            </p>
        </div>
    </div>
  )
}
