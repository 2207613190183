import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router'
import axios from 'axios';
import './CennikSubmit.scss'
import GDPRBody from '../gdpr/GDPRBody';

export default function CennikSubmit() {
    const { categoryId ,indexId } = useParams();
    const [list, setList] = useState([{}])

    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        fname: "",
        lname: '',
        email: '',
        tel: '',
        companyname: '',
        companyico: '',
        devicescount: 0,
        subject: '',
        isgdbrChecked: true
    });

    const fetchSLA = async () => {
        fetch(`${process.env.REACT_APP_VM_URL}/api/${categoryId}`)
        .then(res => res.json())
        .then(data => {
            setList(data[indexId])
        })
        .catch(error => console.error(error))
    } 

    const GetData = () => { 
        return (
            <>
                <div className="sla-info-title">
                    <p>Predmet</p>
                    <p>Limit zariadení</p>
                    <p>Cena</p>
                </div>
                <div className="sla-info">
                    <p> <strong>1x {list.title}</strong></p>
                    <p>{list.minimal_device} - {list.device_limit}</p>
                    <p className='sla-info-price'>{list.price}</p>
                </div>
            </>
        )
    }
    
    useEffect(() => {
        fetchSLA(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const DevicesCheck =  () => { 
        if(categoryId === "sla") { 
            return (
            <div className="sla-slider sla-inputs">
                <p>Zariadenia</p>
                <label htmlFor="device-range">{userData.devicescount}/{list.device_limit}</label>
                <input name='devicescount' required id='device-range' value={userData.devicescount} onChange={handleChange} type="range" min={list.minimal_device} max={list.device_limit}/>
            </div>
            )
        }
    }

    const handleChange = (e) => {
        setUserData(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    useEffect(() => {
        // setUserData(prev => ({...prev, isgdbrChecked: false}))
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        // if(!userData.isgdbrChecked) { return; }
        axios.post(`${process.env.REACT_APP_VM_URL}/mail/${categoryId}`, {
            email: userData.email,
            tel: userData.tel,
            fname: userData.fname,
            lname: userData.lname,
            companyname: userData.companyname,
            companyico: userData.companyico,
            devicescount: userData.devicescount,
            subject: userData.subject,
            item_name: list.title,
            price: list.price,
            description: list.description
        })
        .then(function (response) {
            console.log(response)
            navigate(`/complete`);
        })
        .catch(function (error) {
          console.log(error);
        });
    }


    const gdrp = (status) => { 
        setUserData(prev => ({...prev, isgdbrChecked: status}))
    } 

  return (
    <section id='SLA'>
        <div className="sla-purchase-details sla-container">
            <h1>Zhrnutie</h1>
            <GetData/>
            <h1>Vypnenie údajov</h1>
            <form  className="sla-contact-details" >
                <div className="sla-contact-name sla-inputs">
                    <p>Cele Meno</p>
                    <input type="text" onChange={handleChange} name='fname' placeholder='*Meno' minLength="3" required autoFocus/>
                    <input type="text" onChange={handleChange} name='lname' placeholder='*Priezvisko' minLength="3" required/>
                </div>
                <div className="sla-contact-company sla-inputs">
                    <p>Firma</p>
                    <input type="text" onChange={handleChange} name='companyname' placeholder='Názov Firmy'/>
                    <input type="number" onChange={handleChange} name='companyico' placeholder='Ico'/>
                </div>
                <div className="sla-contact sla-inputs">
                    <p>Kontakt</p>
                    <input type="email" onChange={handleChange} name='email' placeholder='*Email' title='Example: example@example.com' required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></input>
                    <input type="tel" onChange={handleChange} name='tel' placeholder='Tel.Číslo' title='Example: 0900123456' pattern="[0-9]{4}[0-9]{3}[0-9]{3}"/>
                </div>
                {categoryId  === "sla" ? <div className="sla-slider sla-inputs">
                    <p>Zariadenia</p>
                    <label htmlFor="device-range">{userData.devicescount}/{list.device_limit}</label>
                    <input name='devicescount' required id='device-range' value={userData.devicescount} onChange={handleChange} type="range" min={list.minimal_device} max={list.device_limit}/>
                 </div> : "" }
                <div className="sla-contact-textarea sla-inputs">
                    <p>Doplňte info</p>
                    <textarea onChange={handleChange} name='subject' id="" maxLength="456" cols="30" rows="10"></textarea>
                </div>
                <div className="sla-submit-button">
                    <button disabled={userData.isgdbrChecked ? true : false} onClick={handleSubmit}>Poslat</button> 
                    <GDPRBody onClick={gdrp}/>
                </div>
            </form>
        </div>
    </section>
  )
}
