import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import CloudServer from './CloudServer';
import CyberSec from './CyberSec';
import IT from './ItSluzby';

import { ReactComponent as BackgroundSVG } from '../../resources/thissweb_randomobjects-01.svg';
import './WWD.scss'

export default function WWD() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [wasSlided, setWasSlided] = useState(false);
    const [loopSlide, setLoopSlide] = useState()

    const slides = [<CloudServer/>, <CyberSec/>, <IT/>]

    useEffect(() => { // Calling setInterval on start
        const id = setInterval(() => {
                    setCurrentIndex(prevCount => prevCount + 1)
        }, 6000);

        setLoopSlide(id)
    }, [])

    useEffect(() => { // When 
        if(wasSlided) {  // When true stop it
            clearInterval(loopSlide); // This should stop setInterval on top
        }
    }, [wasSlided, loopSlide]) // wasSlided = true (when i press button which work)
    

    useEffect(() => {
        if(currentIndex === slides.length) { 
            setCurrentIndex(0)
        }
    }, [currentIndex])

    const changeSlide = (index) => { 
        setWasSlided(true);
        setCurrentIndex((prevIndex) => prevIndex = index)
    }

    const activeDot = { 
        backgroundColor: '#95B704',
        transform: "scale(1.2)"
    }


    const unactiveDot = { 
        backgroundColor: '#f2f2f2',
    }
    
    return (
        <section id='WWD'>
            {slides[currentIndex]}
            <div className="wwd-dots">
                <div style={currentIndex === 0 ? activeDot : unactiveDot } className="wwd-dot" onClick={() => changeSlide(0)}></div>
                <div style={currentIndex === 1 ? activeDot : unactiveDot } className="wwd-dot" onClick={() => changeSlide(1)}></div>
                <div style={currentIndex === 2 ? activeDot : unactiveDot } className="wwd-dot" onClick={() => changeSlide(2)}></div>
            </div>
        </section>
    )
}
