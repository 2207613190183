import React from 'react'
import './cookiesDocs.scss'
import Header from '../header/Header'
import Footer from '../footer/Footer'


export default function cookiesDocs() {
  return (
    // Cookies Docs
    <>
    <Header/>
    <div className='cookies-docs'>
        <h1> ZÁSADY OCHRANY OSOBNÝCH ÚDAJOV A INFORMÁCIE O SPRACOVANÍ OSOBNÝCH ÚDAJOV </h1>
        <br />
        <p> Radi by sme vás informovali o osobných údajoch, ktoré spoločnosť thiss s.r.o., IČO: 47 669 217, so sídlom Mlynské nivy 56, 821 05 Bratislava, zapísaná v Obchodnom registri Mestského súdu Bratislava III, oddiel: Sro, vložka: 122069/B (ďalej len „Prevádzkovateľ“) ako prevádzkovateľ spracúva na webovej stránke https://www.thiss.tech/ a o spôsobe spracovania týchto údajov. Je pre nás dôležité, aby ste vedeli, aké osobné údaje spracúvame, prečo to robíme a aké máte práva. Prevádzkovateľ zbiera, spracováva a používa osobné údaje najmä pri poskytovaní služieb súvisiacich s prehliadaním webu a analýzou prevádzky našich webových stránok. Snažíme sa chrániť vaše osobné údaje v súlade s príslušnými právnymi predpismi o ochrane osobných údajov, konkrétne podľa NARIADENIA (EÚ) 2016/679 EURÓPSKEHO PARLAMENTU A RADY zo dňa 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov a o zrušení smernice 95/46/ES (ďalej len „Všeobecné nariadenie o ochrane údajov“ alebo „GDPR“) a zákona č. 18/2018 Z. z. o ochrane osobných údajov v znení neskorších predpisov (ďalej len „Zákon“).</p>
        <br />
        <h3> Prevádzkovateľ: </h3>
        <p> thiss s.r.o., IČO: 47 669 217, so sídlom Mlynské nivy 56, 821 05 Bratislava, zapísaný v Obchodnom registri Mestského súdu Bratislava III, oddiel: Sro, vložka: 122069/B</p>
        <br />
        <h3> Účely spracovania údajov:</h3>
        <p> Prevádzkovateľ bude spracovávať vaše údaje pre nasledovné účely:</p>
        <br />
        <p> 1.  umožniť základné funkcie webovej stránky, ako je navigácia na stránke a prístup k zabezpečeným častiam webovej stránky, súvisiacich s prehliadaním webu a analýzou prevádzky webovej stránky, zvýšenie povedomia o službách v online prostredí ("Účel 1"),</p>
        <br />
        <p> 2.  kontaktný formulár určený pre dotknuté osoby, ktoré majú záujem zodpovedať ich otázky a/alebo záujem o poskytnutie služby. Pri vyplnení kontaktného formulára na našej webovej stránke (https://www.thiss.tech/kontakt) zbierame osobné údaje, ktoré nám dobrovoľne poskytnete, napríklad ak nám pošlete špecifickú žiadosť cez zákaznícky servis, podporu alebo o akékoľvek iné súvisiace informácie o našej spoločnosti. Vaše údaje v tomto prípade nebudú použité inak, než na vybavenie vašej žiadosti, napríklad na poskytnutie informácií o službách, o ktoré ste požiadali, alebo na akékoľvek ďalšie informácie o našej spoločnosti ("Účel 2"),</p>
        <br />
        <p> 3.  dodržiavanie zákona - môžeme spracúvať osobné údaje aj na účely riešenia sporov, sťažností, alebo na dodržiavanie zákona ("Účel 3").</p>
        <br />

        <h3> Právny základ spracovania vašich osobných údajov: </h3>
        <p> Pre Účel 1 je právnym základom oprávnený záujem podľa čl. 6 ods. 1 písm. f) GDPR: umožnenie základných funkcií webovej stránky, ako je navigácia na stránke a prístup k zabezpečeným častiam webovej stránky, súvisiacich s prehliadaním webu a analýzou prevádzky webovej stránky, zvýšenie povedomia o službe v online prostredí a/alebo súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. </p>
        <br />
        <p> Pre Účel 2 je právnym základom čl. 6 ods. 1 písm. b) GDPR, podľa ktorého je takéto spracúvanie je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá osoba, alebo aby sa na základe žiadosti dotknutej osoby vykonali opatrenia pred uzatvorením zmluvy a/alebo súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR.</p>
        <br />
        <p> Pre Účel 3 je právnym základom čl. 6 ods. 1 písm. c) GDPR, podľa ktorého je takéto spracovanie nevyhnutné na splnenie zákonných povinností Prevádzkovateľa.</p>
        <br />
        <h3> Kategórie osobných údajov:</h3>
        <p> Spracovávame len základné kategórie osobných údajov o vás, a to: nevyhnutné súbory cookies.</p>
        <p> V prípade poskytnutia súhlasu môžeme spracovávať aj: funkčné súbory cookies, analytické súbory cookies, súbory cookies na sledovanie výkonu, reklamné súbory cookies, iné nezaradené súbory cookies, IP adresu, polohu.</p>
        <br />
        <p> Pokiaľ ide o kontaktný formulár (https://www.thiss.tech/kontakt), môžeme spracovávať aj osobné údaje, ktoré nám poskytnete, a to: meno a priezvisko, e-mailovú adresu, informácie o prehliadači a vašom systéme, ako aj vašu IP adresu alebo akékoľvek ďalšie osobné údaje, ktoré sa rozhodnete nám poskytnúť.</p>
        <br />
        <p> Nespracovávame špeciálne kategórie osobných údajov (citlivé údaje), teda žiadne údaje, ktoré odhaľujú rasový alebo etnický pôvod, politické názory, náboženské alebo filozofické presvedčenie, členstvo v odboroch, genetické údaje, biometrické údaje na jednoznačnú identifikáciu fyzickej osoby, údaje týkajúce sa zdravia alebo údaje týkajúce sa sexuálneho života alebo sexuálnej orientácie fyzickej osoby.</p>
        <br />         
        <h3> Umiestnenie vašich osobných údajov: </h3>
        <p> K Vašim osobným údajom budú mať prístup iba Prevádzkovateľ a jeho sprostredkovatelia. Údaje sa fyzicky nachádzajú na území Slovenskej republiky (Európska únia), niektoré súbory cookies môžu byť v konkrétnych prípadoch prenesené do tretích krajín.</p>
        <br />
        <h3> Uchovávanie osobných údajov: </h3>
        <p> Okrem súborov cookies s rôznymi dobami uchovávania, uchovávame vaše osobné údaje tak dlho, ako je to nevyhnutné na účely, pre ktoré sa spracúvajú. V skutočnosti však pristupujeme k likvidácii alebo anonymizácii osobných údajov ešte pred uplynutím tohto obdobia, ak už považujeme vaše osobné údaje za nepotrebné z hľadiska účelov spracúvania, ak odvoláte svoj súhlas alebo v iných situáciách predpísaných príslušnými právnymi predpismi.</p>
        <br />
        <p> Podľa GDPR musia byť vaše osobné údaje chránené a dôverné. Osobné údaje uchovávame na počítačoch so zabezpečeným prístupom umiestnených v zabezpečených priestoroch.</p>
        <br />
        <p> Zabezpečenie vašich osobných údajov berieme vážne. Máme interné politiky a prísne kontroly, aby sme sa snažili zabezpečiť, že vaše údaje nebudú stratené, náhodne zničené, zneužité, alebo zverejnené a aby sme zabránili neoprávnenému prístupu.</p>
        <br />  
        <h1> SÚBORY COOKIES </h1>
        <h3> Čo sú súbory cookies? </h3>
        <p> Súbor cookie je malý textový súbor, ktorý webová stránka ukladá na váš počítač pri prehliadaní. Vďaka tomuto súboru webová stránka ukladá informácie o vašich akciách a preferenciách na určitý čas, takže pri ďalšej návšteve webovej stránky prehliadač načíta súbory cookies a tieto informácie opäť odosle späť na webovú stránku, ktorá pôvodne súbor cookie vytvorila. Súbory cookies, ktoré používame na našej webovej stránke, neškodia vášmu počítaču.</p>
        <br />
        <p> Môžeme požiadať o povolenie spracovania súborov cookies na vašom zariadení. Súbory cookies môžeme používať na to, aby sme sa dozvedeli, kedy navštívite našu webovú stránku, ako s nami interagujete, a aby sme vám obohatili užívateľský zážitok a prispôsobili váš zážitok našej webovej stránke.</p>
        <br /> 
        <p> Kliknutím na jednotlivé položky kategórie súborov cookies sa môžete dozvedieť viac o ich spracovaní. Môžete tiež zmeniť svoje preferencie. Blokovanie niektorých typov súborov cookies môže ovplyvniť vašu interakciu s našimi webovými stránkami a službami, ktoré môžeme ponúknuť.</p>
        <br /> 
        <p> Používame súbory cookies na pomoc pri efektívnej navigácii a vykonávaní určitých funkcií. Podrobné informácie o všetkých súboroch cookies nájdete pod každou kategóriou súhlasu nižšie.</p>
        <br /> 
        <h3> Nevyhnutné súbory cookies: </h3>
        <p> Súbory cookies, ktoré sú kategorizované ako "Nevyhnutné", sú uložené vo vašom prehliadači, pretože sú nevyhnutné pre povolenie základných funkcií webového sídla. Tieto súbory cookies sú absolútne nevyhnutné na poskytovanie služieb dostupných na našej webovej stránke a na používanie niektorých jej funkcií. Ak by ste odmietli tieto súbory cookies, ovplyvní to to, ako naša stránka funguje.</p>
        <br />
        <h3> Iné ako nevyhnutné súbory cookies: </h3>
        <p> Používame aj súbory cookies tretích strán, ktoré nám pomáhajú analyzovať, ako používate túto webovú stránku, uchovávajú vaše preferencie a poskytujú vám obsah a reklamy relevantné pre vás. Tieto súbory cookies budú uložené vo vašom prehliadači len s vaším predchádzajúcim súhlasom. Poskytujeme vám zoznam súborov cookies uložených vo vašom počítači v našej doméne, aby ste si mohli overiť, čo sme uložili. Môžete si zvoliť, či povolíte alebo zakážete niektoré alebo všetky tieto súbory cookies, ale zakázanie niektorých z nich môže mať vplyv na váš zážitok pri prehliadaní.</p>
        <br /> 
        <p> Súbory cookies Google Analytics: Tieto súbory cookies zbierajú informácie, ktoré sa používajú buď vo zhrnutom tvare na pomoc nám pri pochopení, ako sa používa naša webová stránka, alebo na to, ako efektívne fungujú naše marketingové kampane, alebo nám pomáhajú prispôsobiť našu webovú stránku a aplikáciu tak, aby sme zlepšili váš zážitok. Ak nechcete, aby sme sledovali vašu návštevu na našej webovej stránke, môžete vypnúť spracovanie tohto typu súborov cookies.</p>
        <br /> 
        <p> Iné externé služby: Môžeme tiež používať externé služby ako Google reCaptcha, Google, LinkedIn, Youtube, Instagram, Twitter, tawk.to inc., GitHub, medium.com a/alebo poskytovateľov externých videí. Pretože títo príjemcovia môžu zbierať osobné údaje, ako je vaša IP adresa, umožňujeme vám ich blokovať. Blokovanie však môže výrazne ovplyvniť funkčnosť a vzhľad našej stránky.</p>
        <br />
        <h3> Ako fungujú súbory cookies? </h3>              
        <p> Táto webová stránka používa súbory cookies na zhromažďovanie informácií o vás, o vašej návšteve našich stránok, o obsahu, ktorý ste si prezreli, o odkazoch, ktoré ste sledovali, o čase strávenom na týchto stránkach, o informáciách o vašom prehliadači a zariadení za účelom analýzy informácií o prevádzke, zlepšenia komunikácie a základných funkcií našich webových stránok. Ak ako používateľ nesúhlasíte so používaním súborov cookies, môžete jednoducho prijať alebo odmietnuť používanie súborov cookies jednoduchým kliknutím na príslušné tlačidlo. </p>
        <br />
        <h3> Kategórie spracovávaných súborov cookies na webovej stránke: </h3>
        <br />
        <h3> 1. Nevyhnutné súbory cookies: </h3>
        <p> Účel spracúvania: Nevyhnutné súbory cookies pomáhajú zabezpečiť funkčnosť webovej stránky tým, že umožňujú základné funkcie, ako je navigácia na stránke a prístup k zabezpečeným častiam webovej stránky. Bez týchto súborov cookies webová stránka nemôže správne fungovať. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy, ale predpokladáme, že ako dotknutá osoba môžete byť povinní poskytnúť takéto osobné údaje, pretože inak by možnými následkami neposkytnutia týchto údajov bolo nefungovanie webovej stránky. Nevyhnutné súbory cookies sú potrebné pre zabezpečenie základných funkcií našej webovej stránky, ako je poskytovanie bezpečného prihlásenia alebo nastavenie preferencií súhlasu. Tieto súbory cookies neukladajú žiadne osobne identifikovateľné údaje.</p>
        <br /> 
        <p> Účelom spracúvania je aj umožnenie základných funkcií webovej stránky, ako je navigácia na stránke a prístup k zabezpečeným častiam webovej stránky, súvisiacich s prehliadaním webu a analýzou prevádzky webovej stránky, zvýšenie povedomia o službe v online prostredí (bez nich webová stránka nemôže fungovať) ako aj riadne dodržiavanie pravidiel GDPR na webstránke.</p>
        <br /> 
        <p> Právnym základom spracúvania týchto druhov cookies je oprávnený záujem podľa čl. 6 ods. 1 písm. f) GDPR: umožnenie základných funkcií webovej stránky, ako je navigácia na stránke a prístup k zabezpečeným častiam webovej stránky, súvisiacich s prehliadaním webu a analýzou prevádzky webovej stránky, zvýšenie povedomia o službe v online prostredí (bez nich webová stránka nemôže fungovať) ako aj čl. 6 ods. 1 písm. c) GDPR, podľa ktorého je takéto spracovanie nevyhnutné na splnenie zákonných povinností Prevádzkovateľa.</p>
        <br />
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu.</p>
        <br />
        <h3> 2. Funkčné súbory cookies: </h3>
        <p> Účel spracúvania: Funkčné súbory cookies pomáhajú vykonávať určité funkcionality, ako je zdieľanie obsahu webovej stránky na platformách sociálnych médií, zhromažďovanie spätnej väzby a ďalšie funkcie tretích strán. Kedykoľvek môžete tieto súbory cookies zablokovať a nebudú spracované. Avšak môže to mať vplyv na vašu skúsenosť s používaním. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy a nie ste povinní poskytnúť nám tieto súbory cookies. Avšak ak ich nebudete poskytovať, môže to mať vplyv na vašu možnosť používať našu webstránku.</p>
        <br />
        <p> Právnym základom spracúvania týchto druhov cookies je súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. Tento súhlas môžete kedykoľvek odvolať.</p>
        <br /> 
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu, právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním.</p>
        <br />
        <h3> 3. Analytické súbory cookies: </h3>
        <p> Účel spracúvania: Analytické súbory cookies sa používajú na pochopenie toho, ako návštevníci interagujú so webovou stránkou. Tieto súbory cookies pomáhajú poskytovať informácie o metrikách, ako je počet návštevníkov, miera odchodovosti, zdroj návštevnosti na stránke atď. Kedykoľvek môžete tieto súbory cookies zablokovať a nebudú spracované. Avšak môže to mať vplyv na vašu skúsenosť s používaním. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy a nie ste povinní poskytnúť nám tieto súbory cookies. Avšak ak ich nebudete poskytovať, môže to mať vplyv na vašu možnosť používať našu webstránku.</p>
        <br /> 
        <p> Právnym základom spracúvania týchto druhov cookies je súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. Tento súhlas môžete kedykoľvek odvolať.</p>
        <br />
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu, právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním.</p>
        <br />
        <h3> 4. Výkonové súbory cookies: </h3> 
        <p> Účel spracúvania: Súbory cookies pre výkon sa používajú na pochopenie a analýzu kľúčových ukazovateľov výkonu webovej stránky, čo pomáha poskytovať lepšiu užívateľskú skúsenosť pre návštevníkov. Kedykoľvek môžete tieto súbory cookies zablokovať a nebudú spracované. Avšak môže to mať vplyv na vašu skúsenosť s používaním. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy a nie ste povinní poskytnúť nám tieto súbory cookies. Avšak ak ich nebudete poskytovať, môže to mať vplyv na vašu možnosť používať našu webstránku.</p>
        <br />
        <p> Právnym základom spracúvania týchto druhov cookies je súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. Tento súhlas môžete kedykoľvek odvolať.</p>
        <br /> 
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu, právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním.</p>
        <br />
        <h3> 5. Reklamné súbory cookies: </h3> 
        <p> Účel spracúvania: Reklamné súbory cookies sa používajú na poskytovanie prispôsobených reklám návštevníkom na základe stránok, ktoré navštívili predtým, a na analýzu účinnosti reklamných kampaní. Kedykoľvek môžete tieto súbory cookies zablokovať a nebudú spracované. Avšak môže to mať vplyv na vašu skúsenosť s používaním. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy a nie ste povinní poskytnúť nám tieto súbory cookies. Avšak ak ich nebudete poskytovať, môže to mať vplyv na vašu možnosť používať našu webstránku.</p>
        <br /> 
        <p> Právnym základom spracúvania týchto druhov cookies je súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. Tento súhlas môžete kedykoľvek odvolať.</p>
        <br />
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu, právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním.</p>
        <br />
        <h3> 6. Nezaradené súbory cookies: </h3> 
        <p> Účel spracúvania: Ostatné nezaradené súbory cookies sú tie, ktoré sa môžu spracúvať ale zatiaľ nie sú zaradené do žiadnej kategórie. Kedykoľvek môžete tieto súbory cookies zablokovať a nebudú spracované. Avšak môže to mať vplyv na vašu skúsenosť s používaním. Podľa čl. 13 ods. 2 písm. e) GDPR nie je poskytovanie nevyhnutných súborov cookies zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy a nie ste povinní poskytnúť nám tieto súbory cookies. Avšak ak ich nebudete poskytovať, môže to mať vplyv na vašu možnosť používať našu webstránku.</p>
        <br />
        <p> Právnym základom spracúvania týchto druhov cookies je súhlas dotknutej osoby podľa čl. 6 ods. 1 písm. a) GDPR. Tento súhlas môžete kedykoľvek odvolať.</p>
        <br /> 
        <p> Máte právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov, právo podať sťažnosť dozornému orgánu, právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním.</p>
        <br />
        <h3> Vaše práva podľa GDPR a Zákona: </h3> 
        <p> Na požiadanie vám poskytneme informácie o tom, či sa spracovávajú vaše osobné údaje a aké z nich sa spracovávajú. Ak splníte požiadavky uvedené v všeobecne záväzných právnych predpisoch (predovšetkým v GDPR), máte právo na:</p>
        <ul> 
        <li> prístup k vašim osobným údajom (na základe článku 15 GDPR),</li>
        <li> byť informovaní o primeraných zárukách týkajúcich sa prenosu osobných údajov do tretích krajín (na základe článku 15 GDPR), </li>
        <li> opravu a vymazanie vašich osobných údajov (na základe článku 16 GDPR),</li>
        <li> vymazanie osobných údajov, t. j. "právo na zabudnutie" (na základe článku 17 GDPR),</li>
        <li> obmedzenie spracovania (na základe článku 18 GDPR),</li>
        <li> prenosnosť údajov (na základe článku 20 GDPR),</li>
        <li> právo namietať voči automatizovanému individuálnemu rozhodovaniu (na základe článku 21 GDPR) týkajúcemu sa vašich osobných údajov.</li>
        </ul>
        <p> Taktiež môžete kedykoľvek odvolať súhlas, ktorý ste nám dali, rovnakým spôsobom, akým bol súhlas udelený. Za týmto účelom sa môžete kedykoľvek obrátiť na nás e-mailom na adresu support@thiss.tech alebo písomne na adresu thiss s.r.o., so sídlom Mlynské nivy 56, 821 05 Bratislava. Vašu žiadosť vybavíme bez zbytočného odkladu a v lehotách stanovených všeobecne záväznými právnymi predpismi.</p>
        <br />
        <p> Okrem toho máte právo podať sťažnosť Úradu na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 12, 820 07 Bratislava 27, e-mail: statny.dozor@pdp.gov.sk, ak si myslíte, že došlo k porušeniu spracovania vašich osobných údajov.</p>
        <br />

    
        <a href='https://thiss.tech'>Web: https://thiss.tech</a>
        <br />
        <a href='mailto:support@thiss.tech'>Email: support@thiss.tech</a>
        <br />
        <a href="tel:+421914222000">Tel: +421914222000</a>
        <br />
    </div>

    <Footer/>
    </>
  )
}
