import React from 'react'
import './ReturnPolicy.scss'

export default function ReturnPolicy() {
  return (
    <div className='return-policy'>
        <div>
            <h1>Reklamačný poriadok</h1>
        </div>
        <div>
            <p>
                <br />
                Článok I ÚVODNÉ USTANOVENIA <br />
                1. Spoločnosť thiss s.r.o., so sídlom Mlynské Nivy 56, 821 05 Bratislava, IČO: 47 669 217, zapísaná v Obchodnom registri Okresného súdu Bratislava I, oddiel Sro, vložka č.: 122069/B (ďalej len “thiss” alebo “predávajúci”) týmto reklamačným poriadkom v súlade s § 18 ods. 1 zákona č. 250/2007 Z. z. o ochrane spotrebiteľa a o zmene zákona č. 372/1990 Zb. o priestupkoch v znení neskorších predpisov riadne informuje spotrebiteľa (ďalej aj “zákazník”, “kupujúci” alebo “objednávateľ”) o podmienkach a spôsobe uplatnenia práv zo zodpovednosti za vady (ďalej aj “reklamácia”) vrátane údajov o tom, kde môže reklamáciu uplatniť a o vykonávaní záručných opráv.
                <br />
                <br />
                1.2 Tento reklamačný poriadok je vydaný v súlade so zákonom č. 40/1964 Zb. Občiansky zákonník v znení neskorších predpisov (ďalej len “Občiansky zákonník”), zákonom č. 250/2007 Z.z. o ochrane spotrebiteľa a o zmene zákona Slovenskej národnej rady č. 372/1990 Zb. o priestupkoch v znení neskorších predpisov (ďalej len “Zákon o ochrane spotrebiteľa”), ako aj ostatnými všeobecne záväznými právnymi predpismi Slovenskej republiky.
                <br />
                <br />
                1.3 Tento reklamačný poriadok je v súlade s § 18 ods. 1 Zákona o ochrane spotrebiteľa umiestnený v sídle spoločnosti thiss a zároveň je zverejnený na internetovej stránke spoločnosti.
                <br />
                <br />
                1.4 Tento reklamačný poriadok sa vzťahuje výlučne na zmluvné vzťahy medzi spoločnosťou thiss a zákazníkom, ktorý je spotrebiteľom v zmysle § 2 písm. a) Zákona o ochrane spotrebiteľov. Iné zmluvné vzťahy sa riadia príslušnými zmluvnými dojedaniami, všeobecnými obchodnými podmienkami spoločnosti thiss, zákonom č. 513/1991 Zb. Obchodný zákonník a ďalšími všeobecne záväznými právnymi predpismi.
                <br />
                <br />

                Článok II
                ZODPOVEDNOSŤ za VADY PREDANÉHO TOVARU
                <br />
                
                2.1 Thiss zodpovedá za vady, ktoré má predaný tovar pri prevzatí kupujúcim (§ 619 ods. 1 Občianskeho zákonníka) a za vady, ktoré sa vyskytnú po prevzatí tovaru v záručnej dobe (§ 619 ods. 2 Občianskeho zákonníka). Záručná doba je dvadsaťštyri (24) mesiacov (§ 620 Občianskeho zákonníka).
                <br />
                <br />
                2.2 Pri veciach predávaných za nižšiu cenu z dôvodu vady je záručná doba dvadsaťštyri (24) mesiacov, thiss však nezodpovedá za vadu, pre ktorú bola dojednaná nižšia cena (§ 619 Občianskeho zákonníka).
                <br />
                <br />
                2.3 Pri použitých veciach (napr. predvádzací model) je záručná doba dvanásť (12) mesiacov (§ 620 ods. 2 Občianskeho zákonníka).
                <br />
                <br />
                2.4 Záručné doby začínajú plynúť od dátumu prevzatia tovaru kupujúcim (§ 621 Občianskeho zákonníka).
                <br />
                <br />
                2.5 Doba od uplatnenia práva zo zodpovednosti za vady až do doby, keď kupujúci po skončení opravy bol povinný vec prevziať, sa do záručnej doby nepočíta. Ak dôjde k výmene tovaru, začne plynúť záručná doba znova od prevzatia nového tovaru (§ 627 Občianskeho zákonníka).
                <br />
                <br />
                2.6 Práva zo zodpovednosti za vady tovaru, pre ktoré platí záručná doba zaniknú, ak sa neuplatnili v záručnej dobe (§ 626 ods. 1 Občianskeho zákonníka).
                <br />
                <br />
                2.7 Ak thiss pri kúpe tovaru ponúka zákazníkovi k predanému tovaru bezplatne ďalší tovar ako dar, je na zákazníkovi, či ponúkaný dar príjme. Dar však nie je predaným tovarom, preto thiss nezodpovedá za jeho prípadné vady. Ak však thiss o prípadných vadách daru vie, je povinný na ne zákazníka pri ponuke daru upozorniť (§ 629 Občianskeho zákonníka). Ak má dar vady, na ktoré thiss zákazníka neupozornil, je zákazník oprávnený ho vrátiť (§ 629 Občianskeho zákonníka). Ak zákazníkovi vznikne právo na odstúpenie od zmluvy (vrátenie peňazí), je zákazník povinný vrátiť thiss-u všetko, čo podľa zmluvy dostal, t.j. aj tovar prijatý ako dar (§ 457 Občianskeho zákonníka v spojení s § 48 ods. 2 Občianskeho zákonníka).
                <br />
                <br />
                2.8 thiss nezodpovedá za vady predaného tovaru spôsobené :
                <br />
                <br />
                2.8.1 neodbornou montážou alebo neodborným uvedením tovaru do prevádzky,
                <br />
                <br />
                2.8.2 používaním tovaru v podmienkach nezodpovedajúcich povahe tovaru, príp. uvedených v príslušnej dokumentácii k tovaru,
                <br />
                <br />
                2.8.3 mechanickým poškodením tovaru alebo priamym kontaktom s vodou či inými tekutinami, ktoré sa dostali dovnútra predaného tovaru, ktoré nastalo po jeho prevzatí zákazníkom,
                <br />
                <br />
                2.8.4 použitím iných komponentov, než odporúčaných výrobcom alebo dodávateľom,
                <br />
                <br />
                2.8.5 nesprávnym zaobchádzaním s tovarom, v rozpore s návodom na jeho použitie, nesprávnou obsluhou, ošetrovaním a údržbou tovaru,
                <br />
                <br />
                2.8.6 opotrebením alebo nadmerným používaním veci,
                <br />
                <br />
                2.8.7 opravou alebo modifikáciou tovaru vykonanou inými osobami než autorizovaným servisom,
                <br />
                <br />
                2.8.8 prírodnými živlami,
                <br />
                <br />
                2.9 Zodpovednosť thiss-u za vady predaného tovaru sa ďalej neuplatňuje:
                <br />
                <br />
                2.9.1 na mechanické poškodenie tovaru, príp. iné zjavné vady a nekompletnosť odovzdaného tovaru, ktoré nebolo oznámené predávajúcemu pri prevzatí tovaru a v prípade, ak ide o mechanické poškodenie, ktoré nebolo možné zistiť pri prevzatí, bez zbytočného odkladu po prevzatí tovaru
                <br />
                <br />
                2.9.2 na tovar používaný s vadou, ak používanie tovaru s vadami zabráni odstráneniu vady alebo zväčší rozsah vady,
                <br />
                <br />
                2.9.3 na tovar s porušením ochranných pečatí a nálepiek, ak sú na tovare,
                <br />
                <br />
                2.9.4 na diely spotrebného charakteru spotrebované pred uplynutím záručnej doby,
                <br />
                <br />
                2.9.5 ak kupujúci reklamuje tovar po uplynutí záručnej doby.
                <br />
                <br />
                2.10 Zodpovednosť thiss-u za tovar charakteru SW sa neuplatňuje:
                <br />
                <br />
                2.10.1 Tovar charakteru software sa riadi licenčnými podmienkami a jeho reklamácie sa uplatňujú u výrobcu. Odstránením ochranných prostriedkov sa kupujúci zbavuje možnosti tovar vrátiť predávajúcemu.
                <br />
                <br />
                Článok III
                UPLATNENIE PRÁV zo ZODPOVEDNOSTI za VADY TOVARU (REKLAMÁCIE)
                <br />
                <br />
                3.1 Reklamácia tovaru
                <br />
                <br />
                3.1.1 Kupujúci si uplatňuje právo na opravu v sídle spoločnosti predávajúceho (§ 18 ods. 2 Zákona o ochrane spotrebiteľa). Ak je v záručnom liste uvedený iný podnikateľ určený na opravu (ďalej len “záručný servis”), ktorý je v mieste predávajúceho alebo v mieste pre kupujúceho bližšom, kupujúci si uplatní právo na opravu v tomto záručnom servise (§ 625 Občianskeho zákonníka).
                <br />
                <br />
                3.1.2 Ostatné práva zo zodpovednosti za vady, t.j. právo na výmenu tovaru, právo na odstúpenie od kúpnej zmluvy (vrátenie peňazí) a právo na primeranú zľavu si kupujúci uplatňuje v sídle spoločnosti predávajúceho.
                <br />
                <br />
                3.1.3 V prípade reklamácie uplatnenej do dvanástich (12) mesiacov od kúpy výrobku, predávajúci môže vybaviť reklamáciu zamietnutím len na základe odborného posúdenia. Ak predávajúci zamietne reklamáciu uplatnenú po dvanástich (12) mesiacoch od kúpy výrobku, predávajúci je povinný informovať kupujúceho o tom, komu možno zaslať výrobok na odborné posúdenie. Odborným posúdením sa na účely vybavenia reklamácií rozumie o. i. i stanovisko autorizovanej osoby alebo osoby oprávnenej výrobcom na vykonávanie záručných opráv (§ 2 písm. n) Zákona o ochrane spotrebiteľa).
                <br />
                <br />
                Článok IV
                UPLATNENIE PRÁV ZO ZODPOVEDNOSTI ZA VADY SLUŽBY (OPRAVY)
                <br />
                <br />
                4.1 Reklamácia služby (opravy)
                <br />
                <br />
                4.1.1 Kupujúci si uplatňuje právo na poskytnutie novej služby (odstránenie vady), resp. právo na vrátenie primeranej ceny za vadne poskytnutú službu v sídle spoločnosti predávajúceho (§ 18 ods. 2 Zákona o ochrane spotrebiteľa).
                <br />
                <br />
                4.2 Spoločné ustanovenia pre reklamáciu tovarov a služieb
                <br />
                <br />
                4.2.1 Pri predkladaní tovaru na reklamáciu:
                <br />
                <br />
                a) je potrebné predložiť doklad o kúpe tovaru alebo o poskytnutí služby (oprave),
                <br />
                b) je potrebné, aby tovar spĺňal všetky ostatné podmienky pre záručnú reklamáciu uvedené v záručnom liste, ak bol vydaný (fyzicky nepoškodený, nepoškodený živelnou udalosťou, neporušené plomby atď.).
                <br />
                <br />
                4.2.2 thiss je povinný určiť spôsob vybavenia reklamácie ihneď v zložitých prípadoch najneskôr do troch
                (3) pracovných dní odo dňa uplatnenia reklamácie, v odôvodnených prípadoch, najmä ak sa vyžaduje zložité technické zhodnotenie stavu výrobku alebo služby, najneskôr do tridsiatich (30) dní odo dňa uplatnenia reklamácie (§ 18 ods. 4 Zákona o ochrane spotrebiteľa).
                <br />
                <br />
                4.2.3 Predávajúci je povinný pri uplatnení reklamácie vydať zákazníkovi potvrdenie o prijatí reklamácie (reklamačný protokol) (§ 18 ods. 5 Zákona o ochrane spotrebiteľa).
                <br />
                <br />
                4.2.4 Predávajúci je povinný o vybavení reklamácie vydať písomný doklad najneskôr do tridsiatich (30) dní od dátumu uplatnenia reklamácie (§ 18 ods. 9 Zákona o ochrane spotrebiteľa). Týmto písomným dokladom je kópia reklamačného protokolu s vyplnenou kolónkou vybavenie reklamácie, list (e-mail) obsahujúci písomné vyrozumenie o vybavení reklamácie alebo SMS správa.
                <br />
                <br />
                Článok V
                SPÔSOB VYBAVENIA REKLAMÁCIE
                <br />
                <br />
                5.1 Ak ide o vadu, ktorú možno odstrániť, má kupujúci právo na jej bezplatné, včasné a riadne odstránenie. O spôsobe odstránenia vady rozhoduje predávajúci a je povinný vadu bez zbytočného odkladu odstrániť (§ 622 ods. 1 Občianskeho zákonníka).
                <br />
                <br />
                5.2 Kupujúci môže namiesto odstránenia vady požadovať výmenu tovaru, alebo ak sa vada týka len súčasti tovaru, výmenu tejto súčasti, ak tým predávajúcemu nevzniknú neprimerané náklady vzhľadom na cenu tovaru alebo závažnosť vady (§ 622 ods. 2 Občianskeho zákonníka).
                <br />
                <br />
                5.3 Predávajúci môže vždy namiesto odstránenia vady vymeniť vadný tovar za bezvadný, ak to kupujúcemu nespôsobí závažné ťažkosti (§ 622 ods. 3 Občianskeho zákonníka).
                <br />
                <br />
                5.4 Ak ide o vadu, ktorú nemožno odstrániť, a ktorá bráni tomu, aby sa tovar mohol riadne užívať ako tovar bez vady, má kupujúci právo na výmenu tovaru alebo právo na odstúpenie od kúpnej zmluvy (vrátenie peňazí) (§ 623 ods. 1 Občianskeho zákonníka).
                <br />
                <br />
                5.5 Kupujúci má právo na výmenu tovaru alebo právo na odstúpenie od zmluvy (vrátenie peňazí) aj vtedy, ak ide síce o odstrániteľnú vadu, ak však kupujúci nemôže pre opätovné vyskytnutie sa vady po oprave tovar riadne užívať (§ 623 ods. 1 Občianskeho zákonníka). Za opätovné vyskytnutie sa vady po oprave sa považuje stav, ak sa rovnaká vada vyskytne tretíkrát (3) po jej aspoň dvoch (2) predchádzajúcich opravách.
                <br />
                <br />
                5.6 Kupujúci má právo na výmenu tovaru alebo právo na odstúpenie od zmluvy (vrátenie peňazí) aj vtedy, ak ide síce o odstrániteľné vady, ak však kupujúci nemôže pre väčší počet vád vec riadne užívať. Za väčší počet vád sa považujú súčasne najmenej tri (3) rôzne odstrániteľné vady, pričom každá z nich bráni v riadnom užívaní.
                <br />
                <br />
                5.7 Ak predávajúci reklamáciu nevybaví do tridsiatich (30) dní, kupujúci má rovnaké práva akoby išlo o neodstrániteľnú vadu (§ 18 ods. 4 Zákona o ochrane spotrebiteľa), t.j. právo na výmenu tovaru alebo vrátenie peňazí (odstúpenie od zmluvy).
                <br />
                <br />
                5.8 Ak ide o neodstrániteľnú vadu, ktorá však nebráni riadnemu užívaniu tovaru, má kupujúci právo na primeranú zľavu z ceny výrobku.
                <br />
                <br />
                5.9 Ak má kupujúci právo na výmenu tovaru alebo právo na odstúpenie od zmluvy (vrátenie peňazí), záleží na kupujúcom, ktoré z týchto práv si uplatní. Len čo si však zvolí jedno z týchto práv, nemôže už túto voľbu sám jednostranne meniť.
                <br />
                <br />
                Článok VI
                ZÁVEREČNÉ USTANOVENIA
                <br />
                <br />
                6.1 Tento reklamačný poriadok nadobúda platnosť a účinnosť dňom 01.01.2019.
                <br />
                <br />
                6.2 Thiss si vyhradzuje právo na zmeny tohto reklamačného poriadku bez predchádzajúceho upozornenia.
                <br />
                <br />
                V Bratislave, dňa 01.01.2019
            </p>
        </div>
    </div>
  )
}
