// eslint-disable
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './Popup.scss'

export default function Popup() {
    
    const [isOpen, setIsOpen] = useState(false)
    const [emailSend, setEmailSend] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [closePopup, setClosePopup] = useState(false)


    const [data, setData] = useState({
        email: "",
        fname: "",
        lname: "",
        subject: "",
        isgdbrChecked: false
    });

    const handleChange = (e) => {
        if(e.target.type === 'checkbox') { 
            setData(prev => ({...prev, [e.target.name]: e.target.checked}))
        }
        else { 
            setData(prev => ({...prev, [e.target.name]: e.target.value}))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!data.isgdbrChecked) { return; }
        axios.post(`${process.env.REACT_APP_VM_URL}/mail/contact`, {
          email: data.email,
          fname: data.fname,
          lname: data.lname,
          subject: data.subject
        })
        .then(function (response) {
            setEmailSend(true)
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    useEffect(() => {
        if(emailSend) { 
            setTimeout(() => { 
                setClosePopup(true)
            }, 3000)
        }
    }, [emailSend])
    

    // Popup open/close settings
    const PopupClicked = () => { 
        setIsOpen(c => !c)
    }
    const popUpInterval = 10 * 1000;
    useEffect(() => {
        setTimeout(() => { 
            setIsOpen(true)
        }, popUpInterval)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <div id='Popup' className={closePopup ? "hidden" : ""}>
        {/* Popup Button */}
        <button onClick={PopupClicked} className="popup-button">
            <i className="fa-sharp fa-solid fa-envelope fa-2x"></i>
        </button>
        {/* PopUp Window */}
        <div className={`popup-container ${isOpen ? "show" : "hidden"}`}>
            {/* Success Message */}
            <div className={`success-send ${emailSend ? "show" : ""}`}>
                <div className="success-image">
                    <i className="fa-solid fa-circle-check fa-3x"></i>
                </div>
                <h2>Vaša sprava bola odoslana. Ďakujeme</h2>
            </div>

            {/* Content inside popup window */}
            <div className={`popup-content ${emailSend ? "hidden" : ""}`}>
                <div className="popup-container-close">
                    <button onClick={PopupClicked}>X</button>
                </div>
                <div className="popup-container-header">
                    <h2>Napíšte nám</h2>
                </div>
                <form onSubmit={handleSubmit} action='' method='POST' className="popup-container-form">
                    <input name='fname'  onChange={handleChange} type="text" required placeholder='*Meno' />
                    <input name='lname' onChange={handleChange} type="text" placeholder='Priezvisko' />
                    <input name='email'  onChange={handleChange} type="email"  placeholder='*Email' title='Ukážka: example@example.com' required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></input>
                    <textarea name='subject'  onChange={handleChange} required placeholder='*Napíšte nám správu' id="" cols="30" rows="10"></textarea>
                <div className="popup-container-submit">
                    <button type='submit' disabled={data.isgdbrChecked === true ? false : true } id='popup-submit-button'>ODOSLAŤ</button>
                </div>
                <div className="gdpr">
                    <input onChange={handleChange} name="isgdbrChecked" id='accept-gdpr' type="checkbox" />
                    <label htmlFor="accept-gdpr">Súhlasím so spracovaním <a href='/personal-data'>osobných údajov</a></label>
                </div>
                </form>
            </div>
        </div>
    </div>
  )
}