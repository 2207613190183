import React from 'react'
import { useState,  useEffect} from 'react'
import { Link } from 'react-router-dom'
import ApiCennik from './ApiCennik'
import './CennikBody.scss'

export default function SlaCennikBody() {
    const [list, setList] = useState([])
    const [index, setIndex] = useState(5)
    const [isReady, setReady] = useState(false)

    const fetchSLA = async () => {
        fetch(`${process.env.REACT_APP_VM_URL}/api/sla`)
        .then(res => res.json())
        .then(data => {
            setList(data)
        })
        .catch(error => console.error(error))
    } 

    useEffect(() => {
        fetchSLA();
    }, [])

    useEffect(() => {
        if(list.length > 0) { 
            setReady(true)
        }
    }, [list])

    const apiCennikCB = (currentIndex) => {
        setIndex(prev => currentIndex)
    }

    
    return (
        <section id='cennik'>
            <h1 className='cenova-ponuka'>CENOVA PONUKA</h1>
            <p className='cennik-sla-info-title'>Správu IT poskytujeme na základe uzatvorenej SLA zmluvy, v ktorej Vám garantujeme reakčné časy podľa Vašich potrieb a v ktorej vieme garantovať aj časy riešenia problémov. Našim zákazníkom vieme garantovať aj čas riešenia problému s hardverom a to do 2 hodín od nahlásenia.
            V rámci zmluvného SLA vzťahu neplatíte za odpracovaný čas, máte možnosť vybrať si formu spoplatnenia našich zásahov, a to buď formou paušálu, alebo formou predplatených hodín.</p>
            <div className="cennik-email-redict">
                <Link to="/cennik/email">
                    <h5>Možno máte záujem o Emailové služby ?</h5>
                </Link>
            </div>
            {isReady? <ApiCennik callbackData={apiCennikCB} data={list}/> : ""}
            <p className='cennik-info'>*Ako zariadenie sa počíta všetko ( PC, Wifi router, tlačiareň... )</p>
            <button onClick={() => {window.location.href = `/cennik/continue/sla/${index}/${list[index].url}`}} className='cennik-continue' disabled={index === 5? true : false}>Pokracuj</button>
        </section>
    )
}