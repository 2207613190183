import React from 'react';
import { Link } from 'react-router-dom';


const AdminSluzbyData = ({allData, getBack, removePost}) => {

    const GetAllData = () => { 
        return ( 
            allData.map((result, index) => { 
                return (
                    <div key={index} className="admin-card">
                        <div className="admin-card-head">
                            <Link to={`/sluzby/${result.url_name}`}>
                                <h3>title: ({result.title})</h3>
                            </Link>
                            <p>id: ({result._id})</p>
                        </div>
                        <textarea type="text" readOnly={true} value={result.subject} id="" />
                        <div className="admin-card-update-btn">
                            <button onClick={() => getBack(result._id)}>Select</button>
                            <button className='admin-btn-remove' onClick={() => removePost(result._id)}>Remove</button>
                        </div>
                    </div>
                )
            })
        )
    }


    return (
        <div className='admin-data-cards'>
            <GetAllData/>
        </div>
    );
}

export default AdminSluzbyData;
