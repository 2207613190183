import React from 'react'
import Footer from '../const/footer/Footer'
import Header from '../const/header/Header'
import SlaCompleteBody from '../const/slaComplete/SlaCompleteBody'

export default function SlaComplete() {
  return (
    <>
        <Header/>
        <SlaCompleteBody/>
        <Footer/>
    </>
  )
}
