import React from 'react'

export default function ProductsServices() {
  return (
    <>
      <div className="blog-content-title">
            <h3>Audit IT ako možnosť predídenia hrozbám</h3>
          </div>
          <div className="blog-content-desc">
            <p>Auditom Vašej IT infraštrukúry, dokážete predísť potencionálnym hrozbám, strate cenných údájov a informácií ale aj ušetriť Vaše náklady a zvýšiť efektívnosť a prosperitu Vašej firmy. Po identifikácii možných rozieb, príde návrh na zlepšenie chýb a posilnenie systému alebo príde na rad návrh úplne iného riešenia.</p>
            <h3>Audit zlepšuje celkovú správu IT</h3>
            <p>Správu IT vo vnútri firmy môžeme chápať aj ako organizáciu, postupy a to, že samotné IT oddelenie firmy posilňuje ciele podniku. Taktiež pomáha pri dodržiavaní predpisov, zákonov a smerníc a to zapadá do firemného IT manažmentu.</p>
            <h3>Výhody auditu Vašej IT infraštruktúry</h3>
            <p>Audit pomôže predísť možným hrozbám a taktiež testuje systém či je odolný voči potencionálnym hrozbám. Ak sa v IT systéme objaví diera alebo problém so systémom náš IT auditor navrhne, aké možné zmeny je dobré vykonať na vytvorenie efektívnejšieho IT systému.</p>
            <br />
            <p>Čas zohráva taktiež dôležitú rolu. V niektorých prípadoch platí pravidlo: stráca sa čas? Strácajú sa aj peniaze. Nesprávne nastavený systém fungovania, môže Vašu firmu stáť aj vyššie náklady.</p>
            <br />
            <p>Zlepšuje komunikáciu podniku. Vykonanie IT auditu môže zlepšiť všetku komunikáciu medzi obchodným a technologickým manažmentom spoločnosti. Ukončenie počítačového auditu vytvára túto naliehavú potrebu komunikácie medzi spoločnosťami a ich technologickým oddelením. Technológia spoločnosti existuje na podporu jej funkcií, stratégií a operácií. Zosúladenie obchodnej a podpornej technológie je kľúčové a IT audit toto zosúladenie udržiava.</p>
          </div>
    </>
  )
}
