import React, { useState } from 'react'
import Cookies from 'js-cookie';
import './Cookiespopup.scss'
import { useEffect } from 'react';
import axios from 'axios'
import Switch from "react-switch";

export default function CookiesPopUp() {
    // Cookies Popup
    const [popup, setPopup] = useState(false)
    const [checked, setChecked] = useState(false);

    const handleChange = (isChecked) => {
      setChecked(isChecked);
    };

    useEffect(() => {
      const cookie = Cookies.get('cookies-accept')

      if(cookie) { 
        setPopup(false)
      }
      else { 
        setPopup(true)
      }
    }, []);

    const handlePopup = () => { 
      setPopup(prev => !prev) 
    } 

    const handleDecision = (decision) => { 

      if(decision === false) { 
        setChecked(false)
      }
      Cookies.set('cookies-accept', decision)
      Cookies.set('cookies-marketing', checked)

      axios.post(`${process.env.REACT_APP_VM_URL}/cookies/store`, {
        status: decision,
        marketing: checked
      })

      handlePopup()
      // window.location.reload()
    }

  return (
    <div>
      <div onClick={handlePopup} className='cookies-minimized'>
        <p>Cookies</p>
      </div>
      {popup ? 
      <div className="cookies-background">
          <div className="cookies-popup">
            <h3>Spravujte súhlas so súbormi cookie</h3>
            <div className="cookies-desc">
              {/* <p>Táto webová stránka používa súbory cookie na zlepšenie vášho zážitku pri navigácii po nej. Z týchto súborov cookie sa súbory cookie, ktoré sa kategorizujú podľa potreby, ukladajú vo vašom prehliadači, pretože sú nevyhnutné na fungovanie základných funkcií webovej stránky. Používame tiež cookies tretích strán, ktoré nám pomáhajú analyzovať a porozumieť tomu, ako používate túto webovú stránku. Tieto súbory cookie budú uložené v prehliadači iba so súhlasom používateľa. Máte tiež možnosť zrušiť tieto cookies. Zrušenie niektorých z týchto súborov cookie však môže mať vplyv na vaše prehliadanie.</p> */}
              <p>Na poskytovanie tých najlepších skúseností používame technológie, ako sú súbory cookie na ukladanie a/alebo prístup k informáciám o zariadení. Súhlas s týmito technológiami nám umožní spracovávať údaje, ako je správanie pri prehliadaní alebo jedinečné ID na tejto stránke. Nesúhlas alebo odvolanie súhlasu môže nepriaznivo ovplyvniť určité vlastnosti a funkcie.</p>
            </div>
            <div className="cookies-links">
              <div className="cookies-preferences">
                <div className="cookies-option">
                  <Switch height={14} width={28} checked={true} disabled={true} id='cookies-function-option' onColor={'#95B704'}/>
                  <label htmlFor="cookies-function-option">Funkčné</label>
                </div>
                <div className="cookies-option">
                  <Switch height={14} width={28} onChange={handleChange} checked={checked} id='cookies-marketing-option' onColor={'#95B704'}/>
                  <label htmlFor="cookies-marketing-option">Marketingové</label>
                </div>
              </div>
              <div className="cookies-buttons">
                <button onClick={() => handleDecision(false)} className='cookies-button'>Odmietnut</button>
                <button onClick={() => handleDecision(true)} className='cookies-button'>Prijat</button>
              </div>
              <a className='cookies-terms' href="/zasady-pouzivania-cookies">ZÁSADY POUŽÍVANIA SÚBOROV COOKIE (EÚ)</a>
            </div>

          </div>
        </div> : null}
    </div>
  )
}
